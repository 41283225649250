// src/components/IngestionSettings.jsx

import React, { useState, useEffect, Fragment } from 'react';
import {
  Check,
  Pencil,
  Trash,
  Table,
  User,
  FolderGit2,
  ChevronUp,
} from 'lucide-react';
import { SiGooglebigquery, SiLooker } from 'react-icons/si'; // React-Icons for BigQuery and Looker
import {
  Dialog,
  DialogOverlay,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { cn } from '@/lib/utils';
import {
  getConfigFields,
  getIngestionConfigurations,
  updateIngestionConfig,
  saveIngestionConfig,
  deleteIngestionConfig,
} from '@/utils/ingestion';

const ingestionTypes = [
  {
    name: 'BigQuery',
    id: 'bigquery',
    description: 'Ingest data from Google BigQuery datasets.',
    icon: SiGooglebigquery, // React-Icons
  },
  {
    name: 'Looker',
    id: 'looker',
    description: 'Ingest data from Looker analytics.',
    icon: SiLooker, // React-Icons
  },
  {
    name: 'Clickhouse',
    id: 'clickhouse',
    description: 'Ingest data from Clickhouse databases.',
    icon: Table, // Lucide Icon
  },
  {
    name: 'Users',
    id: 'users',
    description: 'Ingest user data from various sources.',
    icon: User, // Lucide Icon
  },
];

const timeUnits = [
  { id: 'weeks', name: 'Weeks' },
  { id: 'days', name: 'Days' },
  { id: 'hours', name: 'Hours' },
  { id: 'minutes', name: 'Minutes' },
  { id: 'seconds', name: 'Seconds' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const IngestionSettings = () => {
  const [configurations, setConfigurations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [configFields, setConfigFields] = useState([]);
  const [configValues, setConfigValues] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editingConfigId, setEditingConfigId] = useState(null);
  const [intervalValue, setIntervalValue] = useState('');
  const [selectedTimeUnit, setSelectedTimeUnit] = useState(timeUnits[2]); // Default to hours
  const [search, setSearch] = useState(''); // New state for search
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchConfigurations();
  }, []);

  const fetchConfigurations = async () => {
    try {
      setLoading(true);
      const response = await getIngestionConfigurations();
      setConfigurations(response);
      setError(null);
    } catch (error) {
      console.error('Error fetching configurations:', error);
      setError('Failed to fetch configurations. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const openModal = async (type) => {
    setIsEditing(false);
    setSelectedType(type);
    setError(null);

    try {
      const fields = await getConfigFields(type);
      setConfigFields(fields || []); // Ensure it's an array
      setConfigValues(
        (fields || []).reduce((acc, field) => {
          acc[field.name] = '';
          return acc;
        }, {})
      );
    } catch (error) {
      console.error('Error fetching config fields:', error);
      setError('Failed to fetch configuration fields. Please try again.');
      return;
    }

    setIntervalValue('');
    setSelectedTimeUnit(timeUnits[2]); // Reset to default
    setSearch(''); // Reset search
    setIsModalOpen(true);
  };

  const handleInputChange = (field, value) => {
    setConfigValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (intervalValue === '' || isNaN(intervalValue)) {
      setError('Please enter a valid interval value.');
      return;
    }

    const interval = `${intervalValue} ${selectedTimeUnit.id}`;

    const configData = {
      type: selectedType,
      config: configValues,
      interval: interval,
    };

    try {
      setLoading(true);
      if (isEditing) {
        await updateIngestionConfig(editingConfigId, configData);
      } else {
        await saveIngestionConfig(configData);
      }
      setIsModalOpen(false);
      fetchConfigurations();
      setError(null);
    } catch (error) {
      console.error('Error saving configuration:', error);
      setError('Failed to save configuration. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (config) => {
    setIsEditing(true);
    setEditingConfigId(config.id);
    setSelectedType(config.type);
    setError(null);

    try {
      const fields = await getConfigFields(config.type);
      setConfigFields(fields || []); // Ensure it's an array
      setConfigValues(config.config || {});

      const [value, unit] = (config.interval || '').split(' ');
      const foundUnit = timeUnits.find((t) => t.id === unit);
      setIntervalValue(value || '');
      setSelectedTimeUnit(foundUnit || timeUnits[2]);
      setSearch(''); // Reset search
    } catch (error) {
      console.error('Error fetching config fields:', error);
      setError('Failed to fetch configuration fields. Please try again.');
      return;
    }

    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this configuration?')) {
      return;
    }
    try {
      await deleteIngestionConfig(id);
      fetchConfigurations();
      setError(null);
    } catch (error) {
      console.error('Error deleting configuration:', error);
      setError('Failed to delete configuration. Please try again.');
    }
  };

  return (
    <div className="p-4 min-h-screen">
      {/* Removed Ingestion Settings Title */}

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {/* Ingestion Types as Clickable Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-10">
        {ingestionTypes.map((type) => {
          const Icon = type.icon;
          return (
            <div
              key={type.id}
              className="bg-white shadow rounded-lg p-5 cursor-pointer hover:shadow-md transition"
              onClick={() => openModal(type.id)}
            >
              <div className="flex items-center mb-3">
                <Icon className="h-6 w-6 text-indigo-600" />
                {/* Made the title slightly smaller */}
                <h3 className="ml-3 text-base font-medium text-gray-800">{type.name}</h3>
              </div>
              <p className="text-sm text-gray-600">{type.description}</p>
            </div>
          );
        })}
      </div>

      {/* Configurations Table */}
      <div className="bg-white shadow rounded-lg">
        <div className="px-6 py-4">
          <h3 className="text-lg font-medium text-gray-800">Configurations</h3>
        </div>
        {loading ? (
          <div className="p-6">
            <p className="text-sm text-gray-600">Loading configurations...</p>
          </div>
        ) : configurations.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-semibold text-gray-700"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-semibold text-gray-700"
                  >
                    Interval
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {configurations.map((config) => (
                  <tr key={config.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      {config.type.charAt(0).toUpperCase() + config.type.slice(1)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      Every {config.interval}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex justify-end gap-2">
                      {/* Edit Button */}
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleEdit(config)}
                        className="text-gray-600 hover:text-gray-700"
                        aria-label="Edit Configuration"
                      >
                        <Pencil className="h-5 w-5" />
                      </Button>

                      {/* Delete Button */}
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDelete(config.id)}
                        className="text-gray-600 hover:text-gray-700"
                        aria-label="Delete Configuration"
                      >
                        <Trash className="h-5 w-5" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="px-6 py-4">
            <p className="text-sm text-gray-600">
              No configurations found. Add a new configuration by selecting an ingestion type above.
            </p>
          </div>
        )}
      </div>

      {/* Modal for Adding/Editing Configuration */}
      <Dialog open={isModalOpen} onOpenChange={() => setIsModalOpen(false)}>
        {/* Adjusted Overlay: Lighter opacity */}
        <DialogOverlay className="bg-black bg-opacity-25" />

        {/* Modal Content */}
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {isEditing ? 'Edit' : 'Add'}{' '}
              {selectedType && selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}{' '}
              Configuration
            </DialogTitle>
            <DialogDescription>
              {isEditing
                ? 'Update the details of the configuration below.'
                : 'Create a new configuration by providing the necessary details.'}
            </DialogDescription>
          </DialogHeader>

          {error && (
            <Alert variant="destructive" className="mt-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {/* Form */}
          <form onSubmit={handleSubmit} className="mt-4 grid gap-4">
            {configFields.map((field) => (
              <div key={field.name} className="space-y-2">
                <Label htmlFor={field.name}>{field.name}</Label>
                <Input
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  value={configValues[field.name] || ''}
                  onChange={(e) => handleInputChange(field.name, e.target.value)}
                  placeholder={`Enter ${field.name.toLowerCase()}`}
                  required
                />
              </div>
            ))}

            {/* Interval Input */}
            <div className="space-y-2">
              <Label htmlFor="interval">Interval</Label>
              <div className="flex">
                <Input
                  type="number"
                  name="intervalValue"
                  id="intervalValue"
                  value={intervalValue}
                  onChange={(e) => setIntervalValue(e.target.value)}
                  placeholder="Enter number"
                  min="1"
                  className="rounded-r-none"
                  required
                />
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      aria-expanded={false}
                      className="rounded-l-none w-full justify-between"
                    >
                      {selectedTimeUnit.name}
                      <ChevronUp className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-full p-0">
                    <Command>
                      <CommandInput
                        placeholder="Search time units..."
                        value={search}
                        onValueChange={(value) => setSearch(value)}
                      />
                      <CommandEmpty>No results found.</CommandEmpty>
                      <CommandGroup>
                        {timeUnits
                          .filter((unit) =>
                            unit.name.toLowerCase().includes(search.toLowerCase())
                          )
                          .map((unit) => (
                            <CommandItem
                              key={unit.id}
                              onSelect={() => {
                                setSelectedTimeUnit(unit);
                                setSearch('');
                              }}
                              className="flex items-center gap-2"
                            >
                              {selectedTimeUnit.id === unit.id && (
                                <Check className="h-4 w-4 text-indigo-600" />
                              )}
                              <span>{unit.name}</span>
                            </CommandItem>
                          ))}
                      </CommandGroup>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="mt-6 flex justify-end space-x-3">
              <Button
                type="button"
                variant="secondary"
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 text-sm"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="px-4 py-2 text-sm"
                disabled={loading}
              >
                {isEditing ? 'Update' : 'Save'}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default IngestionSettings;
