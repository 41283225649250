import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { formatDistanceToNow, format, isToday, isYesterday, isSameYear, parseISO, differenceInDays } from 'date-fns';
import { Link } from "react-router-dom";
import {
  UserPlus,
  Users,
  Rocket,
  PuzzleIcon,
  FileText,
  RotateCw,
  PlayCircle,
  Workflow,
  Wrench,
  ExternalLink,
  Calendar as CalendarIcon,
  Clock,
  AlertCircle,
  Filter,
  Search as SearchIcon,
  ChevronsDownIcon,
  Info,
  CalendarRange,
  User
} from "lucide-react";
import { SiJupyter, SiLooker, SiGoogledatastudio } from "react-icons/si";
import { fetchData } from "../utils/baseRequest";
import debounce from 'lodash.debounce';
import { cn } from "@/lib/utils";
import { DateRange } from "react-day-picker";
import { Calendar } from "@/components/ui/calendar";


import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const actionTypes = {
  addMember: {
    icon: UserPlus,
    color: 'text-green-500',
    badge: 'New Member',
    bgColor: 'bg-green-500/10',
  },
  CreateDunit: {
    icon: Users,
    color: 'text-blue-500',
    badge: 'Domain Created',
    bgColor: 'bg-blue-500/10',
  },
  ADD_DAG: {
    icon: Workflow,
    color: 'text-cyan-500',
    badge: 'DAG Added',
    bgColor: 'bg-purple-500/10',
  },
  TRIGGER_DAG: {
    icon: PlayCircle,
    color: 'text-yellow-500',
    badge: 'DAG Triggered',
    bgColor: 'bg-yellow-500/10',
  },
  ADD_TASK: {
    icon: PuzzleIcon,
    color: 'text-indigo-500',
    badge: 'Task Added',
    bgColor: 'bg-indigo-500/10',
  },
  UPDATE_TASK: {
    icon: Wrench,
    color: 'text-orange-500',
    badge: 'Task Updated',
    bgColor: 'bg-orange-500/10',
  },
  UPDATE_DAG: {
    icon: Wrench,
    color: 'text-pink-500',
    badge: 'DAG Updated',
    bgColor: 'bg-pink-500/10',
  },
  AddDocument: {
    icon: FileText,
    color: 'text-cyan-500',
    badge: 'Doc Added',
    bgColor: 'bg-cyan-500/10',
  },
  AddNotebook: {
    icon: SiJupyter,
    color: 'text-red-500',
    badge: 'Notebook',
    bgColor: 'bg-red-500/10',
  },
  AddLooker: {
    icon: SiLooker,
    color: 'text-emerald-500',
    badge: 'Looker',
    bgColor: 'bg-emerald-500/10',
  },
  AddDatastudio: {
    icon: SiGoogledatastudio,
    color: 'text-sky-500',
    badge: 'Datastudio',
    bgColor: 'bg-sky-500/10',
  }
};

const getHistoryMessage = (item) => {
  const params = item.params;

  switch (item.action) {
    case 'ADD_DAG':
      return (
        <span className="text-sm text-muted-foreground mt-0.5">
          created a new dag{" "}
          <Link
            to={`/domains/${item.id_domain}/dags/${params.id_dag}`}
            className="font-medium text-primary hover:underline inline-flex items-center"
          >
            {params.dag_name}
            <ExternalLink className="h-3 w-3 ml-1" />
          </Link>
          {params.description && (
            <span className="ml-1">with description: {params.description}</span>
          )}
        </span>
      );
    case 'UPDATE_DAG':
      return (
        <span className="text-sm text-muted-foreground mt-0.5">
          updated dag{" "}
          <Link
            to={`/domains/${item.id_domain}/dags/${params.id_dag}`}
            className="font-medium text-primary hover:underline inline-flex items-center"
          >
            {params.dag_name}
            <ExternalLink className="h-3 w-3 ml-1" />
          </Link>
          {params.changes && (
            <span className="ml-1">changed: {params.changes}</span>
          )}
        </span>
      );
    case 'TRIGGER_DAG':
      return (
        <span className="text-sm text-muted-foreground mt-0.5">
          triggered dag{" "}
          <Link
            to={`/domains/${item.id_domain}/dags/${params.id_dag}`}
            className="font-medium text-primary hover:underline inline-flex items-center"
          >
            {params.dag_name}
            <ExternalLink className="h-3 w-3 ml-1" />
          </Link>
          {params.run_id && (
            <span className="ml-1">with run ID: {params.run_id}</span>
          )}
        </span>
      );
    case 'ADD_TASK':
      return (
        <span className="text-sm text-muted-foreground mt-0.5">
          added new task{" "}
          <Link
            to={`/domains/${item.id_domain}/dags/${params.id_dag}/${params.id_task}`}
            className="font-medium text-primary hover:underline inline-flex items-center"
          >
            {params.task_name}
            <ExternalLink className="h-3 w-3 ml-1" />
          </Link>
          {params.dag_name && (
            <Link
              to={`/domains/${item.id_domain}/dags/${params.id_dag}`}
              className="font-medium text-primary hover:underline inline-flex items-center"
            >
              {params.dag_name}
              <ExternalLink className="h-3 w-3 ml-1" />
            </Link>
          )}
        </span>
      );
    case 'UPDATE_TASK':
      return (
        <span className="text-sm text-muted-foreground mt-0.5">
          updated task{" "}
          <span className="font-medium">
            {params.task_name}
          </span>
          {params.dag_name && (
            <span className="ml-1">
              in DAG{" "}
              <Link
                to={`/domains/${params.id_domain}/dags/${params.id_dag}`}
                className="font-medium text-primary hover:underline inline-flex items-center"
              >
                {params.dag_name}
                <ExternalLink className="h-3 w-3 ml-1" />
              </Link>
            </span>
          )}
          {params.changes && (
            <span className="ml-1">changes: {params.changes}</span>
          )}
        </span>
      );
    case 'ADDMEMBER':
      return (
        <span className="text-sm text-muted-foreground mt-0.5">
          added new member{" "}
          <Link
            to={`/user/${params.id_user_added}`}
            className="font-medium text-primary hover:underline inline-flex items-center"
          >
            {params.user_name_added}
          </Link>
          {params.role && (
            <span className="ml-1">with role: {params.role}</span>
          )}
        </span>
      );
    case 'AddDocument':
    case 'AddNotebook':
    case 'AddLooker':
    case 'AddDatastudio':
      return (
        <span className="text-sm text-muted-foreground mt-0.5">
          added new {actionTypes[item.action].badge.toLowerCase()}{" "}
          <span className="font-medium">
            {params.document_name || params.notebook_name || params.file_name}
          </span>
          {params.location && (
            <span className="ml-1">in {params.location}</span>
          )}
        </span>
      );
    default:
      return (
        <span className="text-sm text-muted-foreground mt-0.5">
          {item.message || `performed ${actionTypes[item.action]?.badge || 'action'}`}
        </span>
      );
  }
};
const DateSeparator = ({ date }) => {
  let dateString;
  const now = new Date();
  if (isToday(date)) {
    dateString = "Today";
  } else if (isYesterday(date)) {
    dateString = "Yesterday";
  } else if (isSameYear(date, now)) {
    dateString = format(date, "MMMM d");
  } else {
    dateString = format(date, "MMMM d, yyyy");
  }
}

const HistoryTable = ({ historyItems, lastItemRef, onFilterChange }) => {
  const [date, setDate] = useState({ from: undefined, to: undefined });
  const [eventTypeFilter, setEventTypeFilter] = useState([]);
  const [userFilter, setUserFilter] = useState("");
  const [domainFilter, setDomainFilter] = useState("");

  const eventTypes = [...new Set(historyItems.map(item => actionTypes[item.action]?.badge || 'Action'))];
  const users = [...new Set(historyItems.map(item => item.params.user_name))];
  const domains = [...new Set(historyItems.map(item => item.id_domain))];

  const filteredItems = useMemo(() => {
    return historyItems.filter(item => {
      const itemDate = parseISO(item.timestamp);
      const dateMatch = (!date.from || itemDate >= date.from) &&
        (!date.to || itemDate <= date.to);
      const eventMatch = eventTypeFilter.length === 0 ||
        eventTypeFilter.includes(actionTypes[item.action]?.badge || 'Action');
      const userMatch = !userFilter || item.params.user_name.toLowerCase().includes(userFilter.toLowerCase());
      const domainMatch = !domainFilter || item.id_domain.toString() === domainFilter;

      return dateMatch && eventMatch && userMatch && domainMatch;
    });
  }, [historyItems, date, eventTypeFilter, userFilter, domainFilter]);

  const handleDateSelect = (selectedDate) => {
    setDate(selectedDate);
    if (onFilterChange) {
      onFilterChange({ date: selectedDate });
    }
  };

  return (
    <Table>
      <TableHeader className="bg-muted/50">
        <TableRow className="hover:bg-transparent">
          {/* Timestamp Column */}
          <TableHead className="w-[180px] py-4">
            <div className="flex items-center gap-3">
              <span className="text-sm font-medium">Time</span>
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="ghost" className={cn("h-8 w-8 p-0", date.from && "text-primary")}>
                    <CalendarIcon className="h-4 w-4" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={date.from}
                    selected={date}
                    onSelect={setDate}
                    numberOfMonths={2}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </TableHead>

          {/* Domain Column */}
          <TableHead className="w-[140px] py-4">
            <div className="flex items-center gap-3">
              <span className="text-sm font-medium">Domain</span>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <ChevronsDownIcon className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start" className="w-56">
                  <DropdownMenuCheckboxItem
                    checked={!domainFilter}
                    onCheckedChange={() => setDomainFilter("")}
                  >
                    All Domains
                  </DropdownMenuCheckboxItem>
                  {domains.map(domain => (
                    <DropdownMenuCheckboxItem
                      key={domain}
                      checked={domainFilter === domain.toString()}
                      onCheckedChange={() => setDomainFilter(domain.toString())}
                    >
                      {domain}
                    </DropdownMenuCheckboxItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </TableHead>

          {/* Event Type Column */}
          <TableHead className="w-[180px] py-4">
            <div className="flex items-center gap-3">
              <span className="text-sm font-medium">Type</span>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <ChevronsDownIcon className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start" className="w-56">
                  {eventTypes.map(type => (
                    <DropdownMenuCheckboxItem
                      key={type}
                      checked={eventTypeFilter.includes(type)}
                      onCheckedChange={(checked) => {
                        setEventTypeFilter(prev =>
                          checked
                            ? [...prev, type]
                            : prev.filter(t => t !== type)
                        );
                      }}
                    >
                      {type}
                    </DropdownMenuCheckboxItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </TableHead>

          {/* User Column */}
          <TableHead className="w-[200px] py-4">
            <div className="flex items-center gap-3">
              <span className="text-sm font-medium">User</span>
              <Input
                className="h-8 w-32"
                placeholder="Filter..."
                value={userFilter}
                onChange={(e) => setUserFilter(e.target.value)}
              />
            </div>
          </TableHead>

          {/* Details Column */}
          <TableHead className="py-4">
            <span className="text-sm font-medium">Details</span>
          </TableHead>
        </TableRow>
      </TableHeader>

      <TableBody>
        {filteredItems.map((item, index) => {
          const actionConfig = actionTypes[item.action] || {
            icon: AlertCircle,
            color: 'text-gray-500',
            badge: 'Action',
            bgColor: 'bg-gray-500/10'
          };
          const IconComponent = actionConfig.icon;

          return (
            <TableRow
              key={item.id}
              ref={index === filteredItems.length - 1 ? lastItemRef : null}
              className="group hover:bg-muted/50"
            >
              {/* Timestamp */}
              <TableCell className="w-[180px] py-4">
                <div className="flex items-center gap-3 text-muted-foreground">
                  <Clock className="h-4 w-4 shrink-0" />
                  <span title={format(parseISO(item.timestamp), 'PPpp')} className="text-sm">
                    {formatDistanceToNow(parseISO(item.timestamp), { addSuffix: true })}
                  </span>
                </div>
              </TableCell>

              {/* Domain */}
              <TableCell className="w-[180px] py-4">
                <Badge
                  variant="secondary"
                  className="bg-background/60 hover:bg-background/80 transition-colors px-3"
                >
                  {item.id_domain}
                </Badge>
              </TableCell>

              {/* Event Type */}
              <TableCell className="w-[200px] py-4">
                <div className="flex items-center gap-3">
                  <div className={cn(
                    'h-8 w-8 rounded-full flex items-center justify-center transition-all group-hover:scale-110 shrink-0',
                    actionConfig.bgColor
                  )}>
                    <IconComponent className={cn("h-4 w-4", actionConfig.color)} />
                  </div>
                  <Badge
                    variant="secondary"
                    className={cn(
                      "px-3 py-1",
                      actionConfig.bgColor,
                      actionConfig.color
                    )}
                  >
                    {actionConfig.badge}
                  </Badge>
                </div>
              </TableCell>

              {/* User */}
              <TableCell className="w-[200px] py-4">
                <Link
                  to={`/user/${item.id_user}`}
                  className="text-sm font-medium hover:underline inline-flex items-center gap-2 text-primary group/link"
                >
                  {item.params.user_name}
                  <ExternalLink className="h-4 w-4 opacity-0 group-hover/link:opacity-100 transition-opacity shrink-0" />
                </Link>
              </TableCell>

              {/* Details */}
              <TableCell className="py-4">
                {getHistoryMessage(item)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const History = ({ hist_type, id_dunit, id_dag, id_task, id_user }) => {
  const [loading, setLoading] = useState(false);
  const [historyItems, setHistoryItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("latest");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [timeRange, setTimeRange] = useState("all");
  const [userFilter, setUserFilter] = useState("all");
  const [users, setUsers] = useState([]);

  const observer = useRef();


  const lastHistoryItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const timer = setTimeout(() => setShowSkeleton(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const [eventFilter, setEventFilter] = useState("all"); // Added event filter state


  const filteredHistoryItems = useMemo(() => {
    let filtered = historyItems;

    // Event type filter
    if (eventFilter !== "all") {
      filtered = filtered.filter(item => {
        switch (eventFilter) {
          case "dags":
            return ["ADD_DAG", "UPDATE_DAG", "TRIGGER_DAG"].includes(item.action);
          case "tasks":
            return ["ADD_TASK", "UPDATE_TASK"].includes(item.action);
          case "members":
            return ["addMember"].includes(item.action);
          case "documents":
            return ["AddDocument", "AddNotebook", "AddLooker", "AddDatastudio"].includes(item.action);
          default:
            return true;
        }
      });
    }

    // Time range filter
    if (timeRange !== "all") {
      const now = new Date();
      filtered = filtered.filter(item => {
        const itemDate = parseISO(item.timestamp);
        switch (timeRange) {
          case "today":
            return isToday(itemDate);
          case "week":
            return differenceInDays(now, itemDate) <= 7;
          case "month":
            return differenceInDays(now, itemDate) <= 30;
          default:
            return true;
        }
      });
    }

    // User filter
    if (userFilter !== "all") {
      filtered = filtered.filter(item => item.id_user === userFilter);
    }

    return filtered;
  }, [historyItems, eventFilter, timeRange, userFilter]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetchData('/users');
        setUsers(["adsf"]);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const fetchHistory = useCallback(
    async (pageNum, isRefresh = false) => {
      let url = '/history/';
      const params = new URLSearchParams({
        limit: '10',
        offset: ((pageNum - 1) * 10).toString(),
      });

      switch (hist_type) {
        case 'dunit':
          url += `domain/${id_dunit}`;
          break;
        case 'dag':
          url += `dag/${id_dag}`;
          break;
        case 'task':
          url += `task/${id_task}`;
          break;
        case 'user':
          url += `user/${id_user}`;
          break;
        default:
          break;
      }

      if (searchTerm) {
        params.append('search', searchTerm);
      }

      if (selectedFilters.length > 0) {
        params.append('actions', selectedFilters.join(','));
      }

      url += `?${params.toString()}`;

      try {
        setLoading(true);
        setError(null);
        const data = await fetchData(url);

        if (isRefresh) {
          setHistoryItems(data);
        } else {
          setHistoryItems((prevHistory) => [...prevHistory, ...data]);
        }
        setHasMore(data.length === 10);
      } catch (error) {
        console.error("Error fetching history:", error);
        setError("Failed to load history. Please try again.");
      } finally {
        setLoading(false);
        setRefreshing(false);
      }
    },
    [hist_type, id_dunit, id_dag, id_task, id_user, searchTerm, selectedFilters]
  );

  useEffect(() => {
    setHistoryItems([]);
    setPage(1);
    setHasMore(true);
    setError(null);
    fetchHistory(1, true);
  }, [hist_type, id_dunit, id_dag, id_task, id_user, searchTerm, selectedFilters, fetchHistory]);

  useEffect(() => {
    if (page > 1) {
      fetchHistory(page);
    }
  }, [page, fetchHistory]);

  const handleRefresh = useCallback(() => {
    setRefreshing(true);
    setPage(1);
    setHasMore(true);
    setError(null);
    fetchHistory(1, true);
  }, [fetchHistory]);

  const handleSearch = useMemo(
    () =>
      debounce((term) => {
        setSearchTerm(term);
        setPage(1);
        setHistoryItems([]);
        setHasMore(true);
      }, 500),
    []
  );

  const handleSort = (option) => {
    setSortOption(option);
    const sorted = [...historyItems].sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);
      return option === "latest" ? dateB - dateA : dateA - dateB;
    });
    setHistoryItems(sorted);
  };

  const groupHistoryByDate = useCallback((items) => {
    const grouped = {};
    items.forEach((item) => {
      const date = new Date(item.timestamp).toDateString();
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(item);
    });
    return grouped;
  }, []);

  const groupedHistory = groupHistoryByDate(historyItems);
  const sortedDates = Object.keys(groupedHistory).sort(
    (a, b) => new Date(b) - new Date(a)
  );

  return (
    <TooltipProvider>
      <Card className="border-0 shadow-none">
        <div>
          {error ? (
            <Alert variant="destructive" className="motion-safe:animate-fadeIn">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          ) : (
            <ScrollArea className="flex-1 rounded-md border motion-safe:animate-fadeIn motion-safe:animate-delay-300">

              <div className="min-w-[1000px]">
                <HistoryTable
                  historyItems={filteredHistoryItems}
                  lastItemRef={lastHistoryItemRef}
                />
              </div>

              {loading && historyItems.length > 0 && (
                <div className="flex justify-center py-4">
                  <RotateCw className="h-5 w-5 animate-spin text-primary" />
                </div>
              )}

              {!loading && !hasMore && historyItems.length === 0 && (
                <div className="text-center py-8 text-muted-foreground">
                  No history available.
                </div>
              )}
            </ScrollArea>
          )}
        </div>
      </Card>
    </TooltipProvider>
  );
};


export default History;