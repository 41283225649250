import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  MiniMap,
  Background,
  getOutgoers,
  getConnectedEdges,
  Panel,
  useReactFlow,
} from "reactflow";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dagre from "dagre";
import {
  Search,
  ZoomIn,
  ZoomOut,
  Maximize2,
  Minimize2,
  RefreshCw,
  Eye,
} from "lucide-react";

import TextUpdaterNode from "./TextUpdaterNode";
import SimpleFloatingEdge from "./SimpleFloatingEdge";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import {
  createDagTask,
  createDagTaskDep,
  deleteDagTask,
  deleteDagTaskDep,
  updateDomainDag,
} from "../../utils/dag";

const edgeTypes = {
  floating: SimpleFloatingEdge,
};

// Define graph styles
const graphStyles = {
  width: "100%",
  height: "100%",
};

const minimapStyle = {
  height: 120,
  maskColor: "#00000066",
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges, direction = "LR") => {
  const isHorizontal = direction === "LR";

  dagreGraph.setGraph({
    rankdir: direction,
    nodesep: 80,
    ranksep: 100,
    edgesep: 200,
  });

  // Add nodes
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: 200, height: 100 });
  });

  // Add edges
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // Layout the graph
  dagre.layout(dagreGraph);

  // Get the positioned nodes
  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      targetPosition: isHorizontal ? "left" : "top",
      sourcePosition: isHorizontal ? "right" : "bottom",
      position: {
        x:
          nodeWithPosition.x -
          nodeWithPosition.width / 2 +
          Math.random() / 1000,
        y: nodeWithPosition.y - nodeWithPosition.height / 2,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

const nodeTypes = {
  textUpdater: (nodeProps) => (
    <TextUpdaterNode
      {...nodeProps}
      data={{
        ...nodeProps.data,
        onSave: nodeProps.data.onSave,
        onConnect: nodeProps.data.onConnect,
        saveFlowState: nodeProps.data.saveFlowState,
      }}
    />
  ),
};

function DnDFlow({ runs, dag }) {
  // Refs and states
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showStatusColors, setShowStatusColors] = useState(true);
  const [layoutDirection, setLayoutDirection] = useState("LR");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [nameSubmitted, setNameSubmitted] = useState({});
  const [edgeChanged, setEdgeChanged] = useState(false);
  const [nodeChanged, setNodeChanged] = useState(false);

  const { dagId, domainId } = useParams();
  const { fitView, zoomIn, zoomOut } = useReactFlow();

  const onSave = (nodeId, name) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id !== nodeId) {
          return node;
        }

        return {
          ...node,
          data: {
            ...node.data,
            name,
          },
        };
      })
    );
    setNameSubmitted({ id: nodeId, name: name });
  };

  const onConnect = useCallback((params) => {
    const { source, target } = params;
    setEdges((eds) => addEdge(params, eds));
    setEdgeChanged(true);
    const body = JSON.stringify({ source, target });
    createDagTaskDep(dagId, body);
  }, []);

  const saveReactFlowState = async (id, name) => {
    console.log(id);
    console.log(name);
    const reactFlowState = { nodes, edges };
    console.log("Saving React Flow state:", reactFlowState);
    const flow = reactFlowInstance.toObject();
    const update_view = {
      view: {
        nodes: flow.nodes,
        edges: flow.edges,
        viewport: flow.viewport,
      },
    };
    const body = JSON.stringify(update_view);
    try {
      const dag = await updateDomainDag(dagId, body);
    } catch (error) {
      console.log("error updating dag", error);
    }
  };

  useEffect(() => {
    if (dag?.view) {
      try {
        const viewObject = dag.view;
        const { nodes: layoutedNodes, edges: layoutedEdges } =
          getLayoutedElements(
            viewObject.nodes,
            viewObject.edges,
            layoutDirection
          );

        const processedNodes = layoutedNodes.map((node) => ({
          ...node,
          width: 200,
          height: 100,
          data: {
            ...node.data,
            last_status: getStatusForNode(node.id),
            showStatus: showStatusColors,
            hasChildren: hasChildNodes(node.id, layoutedEdges),
            isExpanded: true,
            onToggleChildren: handleToggleChildren,
            onSave,
            onConnect,
            saveFlowState
          },
        }));

        setNodes(processedNodes);
        setEdges(layoutedEdges);
      } catch (error) {
        console.error("Error parsing view data:", error);
      }
    }
  }, [dag, layoutDirection, showStatusColors]);

  useEffect(() => {
    if (edgeChanged) {
      saveReactFlowState("x", "y");
      setEdgeChanged(false);
    }
  }, [edgeChanged]);

  useEffect(() => {
    if (nodeChanged) {
      saveReactFlowState("x", "y");
      setNodeChanged(false);
    }
  }, [nodeChanged]);

  // Helper functions
  const hasChildNodes = (nodeId, edgeList) => {
    return edgeList.some((edge) => edge.source === nodeId);
  };

  const getStatusForNode = (taskId) => {
    if (!runs?.tasks_status?.[taskId]?.length) return "UNKNOWN";
    return runs.tasks_status[taskId][runs.tasks_status[taskId].length - 1]
      .status;
  };

  const handleToggleChildren = useCallback(
    (nodeId) => {
      const toggleNode = nodes.find((n) => n.id === nodeId);
      if (!toggleNode) return;

      // Get all descendant nodes and edges recursively
      const getDescendants = (nodeId, visited = new Set()) => {
        if (visited.has(nodeId)) return visited;
        visited.add(nodeId);

        const outgoers = edges
          .filter((e) => e.source === nodeId)
          .map((e) => e.target);

        outgoers.forEach((nodeId) => getDescendants(nodeId, visited));
        return visited;
      };

      const descendants = Array.from(getDescendants(nodeId));
      const affectedEdges = edges.filter(
        (e) => descendants.includes(e.source) || descendants.includes(e.target)
      );

      const isExpanded = !toggleNode.data.isExpanded;

      setNodes((nds) =>
        nds.map((node) => ({
          ...node,
          data: {
            ...node.data,
            isExpanded: node.id === nodeId ? isExpanded : node.data.isExpanded,
          },
        }))
      );

      setEdges((eds) =>
        eds.map((edge) => ({
          ...edge,
          hidden: affectedEdges.includes(edge) && !isExpanded,
        }))
      );
    },
    [nodes, edges]
  );

  const getLatestDagRunStatus = () => {
    if (!runs?.dags_status?.length) return "UNKNOWN";
    return runs.dags_status[runs.dags_status.length - 1].status;
  };

  // Event handlers
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const saveFlowState = useCallback(async () => {
    if (!reactFlowInstance) return;
    const reactFlowState = { nodes, edges };
    const flow = reactFlowInstance.toObject();
    const update_view = {
      view: {
        nodes: flow.nodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            name: node.data.name, // Ensure name is included
            label: node.data.label,
          },
        })),
        edges: flow.edges,
        viewport: flow.viewport,
      },
    };

    console.log("Saving flow state:", update_view); // Debug log

    try {
      await updateDomainDag(dagId, JSON.stringify(update_view));
    } catch (error) {
      console.error("Failed to update DAG view:", error);
    }
  }, [reactFlowInstance, dagId]);

  useEffect(() => {
    if (nameSubmitted && nameSubmitted.id && nameSubmitted.name) {
      saveReactFlowState(nameSubmitted.id, nameSubmitted.name);
    }
  }, [nameSubmitted]);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData("application/reactflow");
      if (!type) return;

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNode = {
        id: uuidv4(),
        type: "textUpdater",
        position,
        width: 200,
        height: 100,
        data: {
          label: type,
          name: "",
          showStatus: showStatusColors,
          hasChildren: false,
          isExpanded: true,
          onToggleChildren: handleToggleChildren,
          onSave,           // Add this
          onConnect,        // Add this
          saveFlowState     // Add this
        },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, showStatusColors, handleToggleChildren]
  );

  const handleSearch = useCallback(
    (event) => {
      const term = event.target.value.toLowerCase();
      setSearchTerm(term);

      if (!term) {
        setNodes((nodes) =>
          nodes.map((node) => ({
            ...node,
            style: undefined,
          }))
        );
        setSuggestions([]);
        return;
      }

      const matchedNodes = nodes.filter((node) =>
        node.data.name?.toLowerCase().includes(term)
      );

      setSuggestions(matchedNodes);

      setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          style: {
            ...node.style,
            opacity: node.data.name?.toLowerCase().includes(term) ? 1 : 0.2,
            transition: "all 0.3s ease",
          },
        }))
      );
    },
    [nodes]
  );

  const handleSearchSelect = useCallback(
    (node) => {
      if (!node || !reactFlowInstance) return;

      // Center view on node with animation
      const x = node.position.x + (node.width || 200) / 2;
      const y = node.position.y + (node.height || 100) / 2;

      reactFlowInstance.setCenter(x, y, { duration: 800 });

      // Highlight the selected node
      setNodes((nodes) =>
        nodes.map((n) => ({
          ...n,
          style: {
            ...n.style,
            opacity: n.id === node.id ? 1 : 0.2,
            boxShadow: n.id === node.id ? "0 0 0 2px #3b82f6" : undefined,
          },
        }))
      );

      setSearchTerm(node.data.name);
      setSuggestions([]);
    },
    [reactFlowInstance]
  );

  const handleLayoutChange = useCallback(
    (newDirection) => {
      setLayoutDirection(newDirection);
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, newDirection);
      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);

      setTimeout(() => {
        fitView({ duration: 200, padding: 0.2 });
      }, 50);
    },
    [nodes, edges, fitView]
  );

  const handleElementsRemoval = useCallback(
    (elementsToRemove) => {
      const edgesToRemove = elementsToRemove.filter(
        (el) => el.source && el.target
      );
      const nodesToRemove = elementsToRemove.filter(
        (el) => !el.source && !el.target
      );

      // First, handle edge deletion
      if (edgesToRemove.length > 0) {
        const remainingEdges = edges.filter(
          (edge) => !edgesToRemove.find((e) => e.id === edge.id)
        );
        setEdges(remainingEdges);
        setEdgeChanged(true);
        edgesToRemove.forEach((edge) => {
          const { source, target } = edge;
          const body = JSON.stringify({ source, target });
          try {
            // deleteDagTaskDep(dagId, body);
          } catch (error) {
            console.log("error deleting edge", error);
          }
        });
      }

      if (nodesToRemove.length > 0) {
        const remainingNodes = nodes.filter(
          (node) => !nodesToRemove.find((n) => n.id === node.id)
        );
        setNodes(remainingNodes);
        setNodeChanged(true);
        nodesToRemove.forEach((node) => {
          try {
            // deleteDagTask(dagId, node.id);
          } catch (error) {
            console.log("error deleting task", error);
          }
        });
      }
    },
    [nodes, edges, dagId, setEdges, setNodes]
  );

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      reactFlowWrapper.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  }, []);



  return (
    <div className="h-full w-full" ref={reactFlowWrapper}>
      <TooltipProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesDelete={handleElementsRemoval}
          onEdgesDelete={handleElementsRemoval}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onDrop={onDrop}
          onDragOver={onDragOver}
          fitView
          minZoom={0.1}
          maxZoom={2}
        >
          {/* Top Left Controls */}
          <Panel position="top-left" className="flex gap-2">
            <Card className="w-[300px]">
              <CardHeader className="p-3">
                <div className="relative">
                  <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                  <Input
                    placeholder="Search nodes..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="pl-8"
                  />
                </div>
              </CardHeader>
              {suggestions.length > 0 && (
                <CardContent className="p-2">
                  <div className="flex flex-col gap-1 max-h-[200px] overflow-auto">
                    {suggestions.map((node) => (
                      <Button
                        key={node.id}
                        variant="ghost"
                        className="justify-start text-left"
                        onClick={() => handleSearchSelect(node)}
                      >
                        <div className="flex items-center gap-2">
                          <div className="w-2 h-2 rounded-full bg-muted-foreground" />
                          <span>{node.data.name}</span>
                        </div>
                      </Button>
                    ))}
                  </div>
                </CardContent>
              )}
            </Card>
          </Panel>

          {/* Top Right Controls */}
          <Panel position="top-right" className="flex flex-col gap-2">
            <Card>
              <CardHeader className="p-3">
                <div className="flex items-center justify-between">
                  <Label htmlFor="show-status">Status Colors</Label>
                  <Switch
                    id="show-status"
                    checked={showStatusColors}
                    onCheckedChange={setShowStatusColors}
                  />
                </div>
              </CardHeader>
              <CardContent className="p-3 pt-0">
                <div className="flex items-center gap-2">
                  <Badge variant="outline">Last Run:</Badge>
                  <Badge
                    variant={
                      getLatestDagRunStatus() === "SUCCESS"
                        ? "success"
                        : getLatestDagRunStatus() === "FAILED"
                        ? "destructive"
                        : "secondary"
                    }
                  >
                    {getLatestDagRunStatus()}
                  </Badge>
                </div>
              </CardContent>
            </Card>
          </Panel>

          {/* Bottom Left Controls */}
          <Controls
            className="bg-background border rounded-lg shadow-sm"
            showInteractive={false}
          >
            <Button
              variant="ghost"
              size="sm"
              className="h-10 w-10"
              onClick={toggleFullscreen}
            >
              {isFullscreen ? (
                <Minimize2 className="h-4 w-4" />
              ) : (
                <Maximize2 className="h-4 w-4" />
              )}
            </Button>
          </Controls>

          <Background />

          <MiniMap
            style={minimapStyle}
            zoomable
            pannable
            className="bg-background/80 border rounded-lg shadow-sm"
          />

          {/* Layout Controls */}
          <Panel position="bottom-right" className="flex gap-2">
            <Select value={layoutDirection} onValueChange={handleLayoutChange}>
              <SelectTrigger className="w-[150px]">
                <SelectValue placeholder="Layout Direction" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="LR">Left to Right</SelectItem>
                <SelectItem value="TB">Top to Bottom</SelectItem>
                <SelectItem value="RL">Right to Left</SelectItem>
                <SelectItem value="BT">Bottom to Top</SelectItem>
              </SelectContent>
            </Select>
          </Panel>
        </ReactFlow>
      </TooltipProvider>
    </div>
  );
}

export default function WrappedDnDFlow(props) {
  return (
    <ReactFlowProvider>
      <DnDFlow {...props} />
    </ReactFlowProvider>
  );
}
