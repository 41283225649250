// components/tasks/DagTaskItemConf.jsx

import React, { useState, useEffect } from "react";
import CommonTask from "../tasks/CommonTask";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOperatorsInfo } from "../../utils/resources";
import PermissionButton from "../common/PermissonButton";
import { PERMISSIONS } from "../../context/permissions";
import { useToast } from "@/components/hooks/use-toast";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

// Import CodeMirror and language extensions
import CodeMirror from "@uiw/react-codemirror";
import { sql } from "@codemirror/lang-sql";
import { python } from "@codemirror/lang-python";
// Import themes
import { dracula } from "@uiw/codemirror-theme-dracula";
import { oneDark } from "@codemirror/theme-one-dark";

import { Copy } from "lucide-react"; // Icon for the copy button
import { ToastProvider } from "../ui/toast";

const DagTaskItemConf = ({ task }) => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [taskType, setTaskType] = useState(null);
  const [theme, setTheme] = useState("dracula"); // State for theme selection
  const navigate = useNavigate();
  const { dagId, domainId } = useParams();
  const { toast } = useToast(); // For notifications

  useEffect(() => {
    getTaskTypeInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTaskTypeInfo = async () => {
    try {
      const data = await fetchOperatorsInfo(task.task_type);
      setTaskType(data);
    } catch (error) {
      console.error("Error fetching task type info:", error);
    }
  };

  const handleEdit = () => {
    setShowTaskModal(true);
  };

  const closeTaskModal = () => {
    setShowTaskModal(false);
  };

  const taskParams = task.params || {};

  const mergedParams =
    taskType?.fields.reduce((acc, field) => {
      acc[field.name] = taskParams[field.name] || field.default || "";
      return acc;
    }, {}) || {};

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    toast({
      description: "Code copied to clipboard!",
    });
  };

  const getThemeObject = () => {
    switch (theme) {
      case "dracula":
        return dracula;
      case "oneDark":
        return oneDark;
      default:
        return dracula;
    }
  };

  return (
    <ToastProvider>
      <div className="py-4">

        <Card>
          <CardHeader className="pb-4">
            <div className="flex justify-between items-center">
              <CardTitle className="text-base font-semibold">
                Task Configuration
              </CardTitle>
              <PermissionButton
                onClick={handleEdit}
                permission={PERMISSIONS.UPDATE_TASK}
                domainId={domainId}
                variant="outline"
              >
                Edit
              </PermissionButton>
            </div>
          </CardHeader>
          <Separator />
          <CardContent className="pt-4">
            <div className="grid gap-4">
              {taskType &&
                Object.entries(mergedParams).map(([key, value], index) => (
                  <div
                    key={key}
                    className={cn(
                      "grid grid-cols-1 sm:grid-cols-3 gap-4 p-4 rounded-md",
                      index % 2 === 0 ? "bg-muted" : "bg-background"
                    )}
                  >
                    <Label className="text-sm font-medium text-muted-foreground">
                      {key.replace(/_/g, " ")}
                    </Label>
                    <div className="sm:col-span-2">
                      {["bq_sql", "python", "sql"].includes(key) && value ? (
                        <div className="mb-2">
                          {/* Header with Copy Button and Theme Selector */}
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex items-center space-x-2">
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => handleCopy(value)}
                              >
                                <Copy className="h-4 w-4 mr-1" />
                                Copy
                              </Button>
                            </div>
                            {/* Theme Selector */}
                            <Select value={theme} onValueChange={setTheme}>
                              <SelectTrigger className="w-[150px]">
                                <SelectValue placeholder="Select Theme" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="dracula">Dracula</SelectItem>
                                <SelectItem value="oneDark">One Dark</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          {/* Code Editor */}
                          <CodeMirror
                            value={value}
                            extensions={[key === "python" ? python() : sql()]}
                            theme={getThemeObject()}
                            editable={false}
                            basicSetup={{
                              lineNumbers: false,
                              highlightActiveLine: false,
                              foldGutter: false,
                              highlightSelectionMatches: false,
                            }}
                            style={{
                              height: "auto",
                              borderRadius: "5px",
                              border: "1px solid #e2e8f0",
                            }}
                            className="overflow-hidden"
                          />
                        </div>
                      ) : (
                        <p className="text-sm text-foreground">{value}</p>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </CardContent>
        </Card>
        {showTaskModal && (
          <CommonTask
            taskId={task.id_task}
            task_type={task.task_type}
            onCloseTask={closeTaskModal}
          />
        )}
      </div>
    </ToastProvider>
  );
};

export default DagTaskItemConf;
