import React, { useState, useEffect, Fragment } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { FaFile, FaFolder, FaTable } from 'react-icons/fa'; // Example icons
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { SiGoogledocs } from "react-icons/si";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const options = [
    { title: 'Import', description: 'Import', current: false },
    { title: 'Upload', description: 'Upload ', current: false },
];

export default function DocAssetList() {

    const [docs, setDocs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);
    const [gdocsUrl, setGdocsUrl] = useState("");
    const [docName, setDocName] = useState("");
    const { domainId } = useParams();
    const [selected, setSelected] = useState(options[0]);


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`http://localhost:8080/resources/${domainId}/docs/upload/`, {
                method: 'POST',
                body: formData
            });

            const result = await response.json();
        } catch (error) {
            console.error("Error while uploading docs:", error);
        }
    };

    const handleGdocsImport = async () => {
        const response = await fetch(`http://localhost:8080/resources/${domainId}/docs/import/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: gdocsUrl, name: docName })
        });
        // Handle response...
    };

    useEffect(() => {
        const fetchDocs = async () => {
            try {
                const response = await fetch(`http://localhost:8080/resources/${domainId}/doc/`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setDocs(data);
            } catch (error) {
                console.error("A problem occurred with fetching the projects:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchDocs();
    }, []);

    if (loading) return <div className="py-4">Loading...</div>;
    if (error) return <div className="py-4 text-red-500">Error fetching projects: {error}</div>;

    const renderContent = () => {
        switch (selected.title) {
            case 'Upload':
                return <div className='flex w-3/4 items-center '>
                    <div className='mr-2'>
                        <input className='block rounded-md border-0 py-1.5 pl-2  text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' type="file" onChange={handleFileChange} />
                    </div>
                    <div className=''>
                        <button className='rounded-md bg-white px-3 py-1.5 text-sm font-semibold  text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50' onClick={handleFileUpload}>Upload</button>
                    </div>
                </div>;
            case 'Import':
                return (
                    <div className='flex items-center space-x-5 w-full '>
                        {/* First input taking up half the space */}
                        <div className="w-1/2">
                            <input
                                type="text"
                                name="document_url"
                                id="document_url"
                                className="block w-full rounded-md border-0 py-2.5 pl-2  text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="type document url"
                                value={gdocsUrl}
                                onChange={(e) => setGdocsUrl(e.target.value)}
                            />
                        </div>
                        {/* Second input and button sharing the other half */}
                        <div className="w-1/2 flex items-center">
                            <input
                                type="text"
                                name="document_name"
                                id="document_name"
                                className="block w-3/5 rounded-md border-0 py-2.5  text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="document name"
                                value={docName}
                                onChange={(e) => setDocName(e.target.value)}
                            />
                            <button
                                className='ml-3 rounded-md bg-white px-3 py-1.5 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                                onClick={handleGdocsImport}
                            >
                                Import from Google Docs
                            </button>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className=" bg-white">
            <div className='flex justify-between'>
                {renderContent()}
                <div className='flex    relative text-left'>
                    <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                            <>
                                <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                                <div className="relative">
                                    <div className="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
                                        <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm">
                                            <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                            <p className="text-sm font-semibold">{selected.title}</p>
                                        </div>
                                        <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                                            <span className="sr-only">Change published status</span>
                                            <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                        </Listbox.Button>
                                    </div>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {options.map((option) => (
                                                <Listbox.Option
                                                    key={option.title}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active ? 'bg-indigo-600 text-white' : ' text-gray-600',
                                                            'cursor-default select-none p-4 text-sm'
                                                        )
                                                    }
                                                    value={option}
                                                >
                                                    {({ selected, active }) => (
                                                        <div className="flex flex-col">
                                                            <div className="flex justify-between">
                                                                <p className={selected ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                                                                {selected ? (
                                                                    <span className={active ? 'text-white' : 'text-indigo-600'}>
                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                            <p className={classNames(active ? 'text-indigo-200' : 'text-gray-600', 'mt-2')}>
                                                                {option.description}
                                                            </p>
                                                        </div>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
            </div>

            <div className="text-lg font-medium mb-2 mt-6">Documents</div>
            {docs.length > 0 ? (
                <ul className="divide-y divide-gray-200">
                    {docs.map((doc, index) => (
                        <li key={index} className="flex items-center py-4">
                            {doc.doc_type === 'spreadsheet' && <BsFileEarmarkSpreadsheetFill className="mr-2 text-3xl text-green-400" />}
                            {doc.doc_type === 'document' && <SiGoogledocs className="mr-2 text-3xl text-blue-400" />}
                            {doc.doc_type === 'folder' && <FaFolder className="mr-2 text-3xl text-gray-400" />}
                            <Link
                                to={`/domains/${domainId}/assets/docs/${doc.id_doc}`}
                                className="text-black text-sm hover:text-gray-800 hover:underline cursor-pointer"
                            >
                                {doc.name}
                            </Link>
                        </li>
                    ))}
                </ul>

            ) : (
                <p className="text-gray-600">No projects found.</p>
            )}
        </div>
    );
}
