import { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";
import { SelectedContext } from "../context/SelectedContext";
import { Switch } from "@headlessui/react";
import { PlayIcon, PauseIcon } from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";

import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import {
  BuildingOfficeIcon,
  CreditCardIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
const parser = require("cron-parser");

// const navigation = [
//   { name: "Home", href: "#", icon: HomeIcon, current: true },
//   { name: "Teams", href: "#", icon: UsersIcon, current: false },
//   { name: "Documents", href: "#", icon: DocumentDuplicateIcon, current: false },
//   { name: "Reports", href: "#", icon: ChartPieIcon, current: false },
//   { name: "Settings", href: "#", icon: Cog6ToothIcon, current: false },
// ];

const navigation = [
  { name: "Home", path: "/", icon: HomeIcon },
  { name: "Teams", path: "/teams", icon: UsersIcon },
  { name: "Documents", path: "/documents", icon: DocumentDuplicateIcon },
  { name: "Reports", path: "/reports", icon: ChartPieIcon },
  { name: "Settings", path: "/settings", icon: Cog6ToothIcon },
];

const tabs = [
  { name: "Details", href: "#", icon: BuildingOfficeIcon, current: false },
  { name: "Team Members", href: "#", icon: UsersIcon, current: true },
  { name: "Dags", href: "#", icon: CreditCardIcon, current: false },
  { name: "Assets", href: "#", icon: CreditCardIcon, current: false },
];

const teams = [
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
];


const userNavigation = [
  { name: "Profile", href: "#" },
  { name: "Sign out", href: "#" },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Teams = () => {


  return <>Settings</>;
};

export default Teams;
