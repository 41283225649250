import { useState, useEffect } from "react";
import { useParams, useMatch, useLocation, NavLink, Outlet, useNavigate } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from "@/components/ui/select";
import { SiGooglebigquery, SiLooker, SiGoogledocs, SiJupyter } from 'react-icons/si';
import { BiData, BiFolder, BiCube } from "react-icons/bi";
import { 
  ChevronLeft, 
  ChevronRight, 
  Menu 
} from "lucide-react";
import { cn } from "@/lib/utils";

const tabs = [
  { name: 'BigQuery', href: 'bigquery', icon: SiGooglebigquery },
  { name: 'Docs', href: 'docs', icon: SiGoogledocs },
  { name: 'Notebooks', href: 'notebooks', icon: SiJupyter },
  { name: 'Looker', href: 'lookers', icon: SiLooker },
  { name: 'Data Studio', href: 'datastudios', icon: BiData },
  { name: 'GCS', href: 'gcs', icon: BiFolder },
  { name: 'Models', href: 'models', icon: BiCube },
  // ... more tabs
];

export default function DomainAssets() {
  const { domainId } = useParams();
  const match = useMatch("domains/:domainId/assets/*");
  const location = useLocation();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  
  const isActive = (href) => location.pathname.includes(href);

  // Set default route to BigQuery if no tab is selected
  useEffect(() => {
    if (match && !tabs.some(tab => location.pathname.includes(tab.href))) {
      navigate(`/domains/${domainId}/assets/bigquery`);
    }
  }, [location.pathname, domainId, match]);

  const resolvePath = (path) => {
    if (match) {
      return `/domains/${domainId}/${path}`;
    }
    return path;
  };

  const getTabStyles = (active) => cn(
    "flex items-center gap-3 rounded-md px-3 py-2 text-sm transition-colors",
    "hover:bg-muted whitespace-nowrap",
    active 
      ? "bg-muted text-indigo-600 font-medium" 
      : "text-foreground hover:text-foreground/80"
  );

  return (
    <div className="flex h-full">
      {/* Mobile Menu Button */}
      <Button
        variant="ghost"
        size="icon"
        className="lg:hidden fixed left-4 top-4 z-50"
        onClick={() => setIsMobileOpen(!isMobileOpen)}
      >
        <Menu className="h-5 w-5" />
      </Button>

      {/* Mobile Menu */}
      <div className={cn(
        "fixed inset-y-0 left-0 z-40 w-72 bg-background border-r transition-transform duration-200 lg:hidden",
        isMobileOpen ? "translate-x-0" : "-translate-x-full"
      )}>
        <div className="h-full py-16">
          <ScrollArea className="h-full">
            <div className="space-y-1 px-3">
              {tabs.map((tab) => {
                const active = isActive(tab.href);
                return (
                  <NavLink
                    key={tab.href}
                    to={tab.href}
                    onClick={() => setIsMobileOpen(false)}
                    className={getTabStyles(active)}
                  >
                    <tab.icon className={`h-4 w-4 flex-shrink-0 ${active ? "text-indigo-600" : ""}`} />
                    <span className="truncate">{tab.name}</span>
                  </NavLink>
                );
              })}
            </div>
          </ScrollArea>
        </div>
      </div>

      {/* Desktop Sidebar */}
      <div className={cn(
        "hidden lg:flex border-r transition-all duration-300",
        isCollapsed ? "w-16" : "w-64"
      )}>
        <div className="relative flex flex-col w-full">
          {/* Collapse Button */}
          <Button
            variant="ghost"
            size="icon"
            className="absolute -right-3 top-4 z-10 h-6 w-6 rounded-full border bg-background shadow-sm hover:bg-muted"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? (
              <ChevronRight className="h-4 w-4" />
            ) : (
              <ChevronLeft className="h-4 w-4" />
            )}
          </Button>

          <ScrollArea className="flex-1 py-8">
            <div className="space-y-1 px-3">
              {tabs.map((tab) => {
                const active = isActive(tab.href);
                return (
                  <NavLink
                    key={tab.href}
                    to={tab.href}
                    className={getTabStyles(active)}
                  >
                    <tab.icon className={`h-4 w-4 flex-shrink-0 ${active ? "text-indigo-600" : ""}`} />
                    <span className={cn(
                      "truncate transition-opacity duration-200",
                      isCollapsed ? "opacity-0 w-0" : "opacity-100"
                    )}>
                      {tab.name}
                    </span>
                  </NavLink>
                );
              })}
            </div>
          </ScrollArea>
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-hidden">
        <div className="h-full p-4 lg:p-8">
          <Outlet />
        </div>
      </div>

      {/* Mobile Overlay */}
      {isMobileOpen && (
        <div 
          className="fixed inset-0 bg-black/50 z-30 lg:hidden"
          onClick={() => setIsMobileOpen(false)}
        />
      )}
    </div>
  );
}