import React, { useState, useEffect, Fragment } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { FaFile, FaFolder, FaTable } from 'react-icons/fa'; // Example icons
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { SiGoogledocs } from "react-icons/si";
import { SiGooglecolab } from "react-icons/si";
import { SiJupyter } from "react-icons/si";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const options = [
    { title: 'Import', description: 'Import', current: false },
    { title: 'Upload', description: 'Upload ', current: false },
];

export default function NotebookAssetList() {

    const [notebooks, setNotebooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);
    const [gdocsUrl, setGdocsUrl] = useState("");
    const [docName, setDocName] = useState("");
    const { domainId } = useParams();
    const [selected, setSelected] = useState(options[0]);


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`http://localhost:8080/resources/${domainId}/docs/upload/`, {
                method: 'POST',
                body: formData
            });

            const result = await response.json();
        } catch (error) {
            console.error("Error while uploading docs:", error);
        }
    };

    const handleGdocsImport = async () => {
        const response = await fetch(`http://localhost:8080/resources/${domainId}/notebooks/import/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: gdocsUrl, name: docName })
        });
        // Handle response...
    };

    useEffect(() => {
        const fetchNotebooks = async () => {
            try {
                const response = await fetch(`http://localhost:8080/resources/${domainId}/notebook/`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setNotebooks(data);
            } catch (error) {
                console.error("A problem occurred with fetching the projects:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchNotebooks();
    }, []);

    if (loading) return <div className="py-4">Loading...</div>;
    if (error) return <div className="py-4 text-red-500">Error fetching projects: {error}</div>;

    const renderContent = () => {

        return (
            <div className='flex items-center space-x-5 w-full '>
                {/* First input taking up half the space */}
                <div className="w-1/2">
                    <input
                        type="text"
                        name="document_url"
                        id="document_url"
                        className="block w-full rounded-md border-0 py-2.5 pl-2  text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="type document url"
                        value={gdocsUrl}
                        onChange={(e) => setGdocsUrl(e.target.value)}
                    />
                </div>
                {/* Second input and button sharing the other half */}
                <div className="w-1/2 flex items-center justify-between">
                    <input
                        type="text"
                        name="document_name"
                        id="document_name"
                        className="block w-3/5 rounded-md border-0 py-2.5  text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="document name"
                        value={docName}
                        onChange={(e) => setDocName(e.target.value)}
                    />
                    <button
                        className='ml-3 rounded-md bg-white px-3 py-1.5 text-sm font-semibold   shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                        onClick={handleGdocsImport}
                    >
                        Import Notebook
                    </button>
                </div>
            </div>
        );

    };


    return (
        <div className=" bg-white">
            <div className='flex justify-between'>
                {renderContent()}
            </div>

            <div className="text-lg font-medium mb-2 mt-6">Notebooks</div>
            {notebooks.length > 0 ? (
                <ul className="divide-y divide-gray-200">
                    {notebooks.map((doc, index) => (
                        <li key={index} className="flex items-center py-4">
                            <SiJupyter className="mr-2 text-3xl text-orange-400" />

                            <Link
                                to={`/domains/${domainId}/assets/notebooks/${doc.id_notebook}`}
                                className="text-black text-sm hover:text-gray-800 hover:underline cursor-pointer"
                            >
                                {doc.name}
                            </Link>
                        </li>
                    ))}
                </ul>

            ) : (
                <p className="text-gray-600">No projects found.</p>
            )}
        </div>
    );
}
