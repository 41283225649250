import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { VscDatabase } from "react-icons/vsc"; // Icon for datasets
import { fetchData } from '../../utils/baseRequest';
import { cn } from "@/lib/utils";

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import ReactQuill from 'react-quill';

export default function BqAssetDatasetList() {
    const { projectId, domainId } = useParams();

    const [datasets, setDatasets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentTab, setCurrentTab] = useState("Datasets");
    const [projectInfo, setProjectInfo] = useState(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust as needed

    // Determine prefixUrl based on domainId
    const prefixUrl = domainId ? `/domains/${domainId}` : '';

    const tabs = [
        { name: 'Datasets', onClick: () => setCurrentTab("Datasets"), current: currentTab === 'Datasets' },
        { name: 'Documentation', onClick: () => setCurrentTab("Documentation"), current: currentTab === 'Documentation' }
    ];

    // Fetch project information
    const fetchProjectInfo = async () => {
        try {
            const response = await fetchData(`/resources/projects/${projectId}`);
            setProjectInfo(response);
        } catch (error) {
            console.error("Error fetching project info:", error);
            setError("Failed to fetch project information.");
        }
    };

    // Fetch datasets
    const fetchDatasets = async () => {
        try {
            const response = await fetchData(`/resources/projects/${projectId}/datasets`);
            const datasetFields = response.datasets.map(item => item.dataset);
            setDatasets(datasetFields);
        } catch (error) {
            console.error("A problem occurred with fetching the datasets:", error);
            setError("Failed to fetch datasets.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDatasets();
        fetchProjectInfo();
    }, [projectId]);

    // Filtered Datasets based on Search Term
    const filteredDatasets = useMemo(() => {
        return datasets.filter(dataset =>
            dataset.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [datasets, searchTerm]);

    // Pagination Logic
    const totalPages = Math.ceil(filteredDatasets.length / itemsPerPage);
    const paginatedDatasets = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredDatasets.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredDatasets, currentPage, itemsPerPage]);

    // Handle Page Change
    const handlePageChange = (direction) => {
        if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        } else if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };

    if (loading) return <div className="py-4 text-center">Loading...</div>;
    if (error) return (
        <Alert variant="destructive" className="my-4">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
        </Alert>
    );

    return (
        <div className="min-h-screen">
            {/* Dataset List Card */}
            <Card>
                <CardHeader className="flex flex-col sm:flex-row justify-between items-center mb-4">
                    <CardTitle className="text-lg">BigQuery Datasets</CardTitle>
                    <div className="mt-4 sm:mt-0 flex flex-col sm:flex-row items-center gap-4">
                        {/* Search Input */}
                        <Input
                            type="text"
                            placeholder="Search datasets..."
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setCurrentPage(1); // Reset to first page on search
                            }}
                            className="w-full sm:w-64 text-sm"
                        />
                        {/* Tab Buttons */}
                        <div className="hidden sm:flex space-x-4">
                            {tabs.map((tab) => (
                                <Button
                                    key={tab.name}
                                    variant={tab.current ? "primary" : "ghost"}
                                    onClick={tab.onClick}
                                    className={cn(
                                        tab.current ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-700",
                                        "whitespace-nowrap px-3 py-2 rounded-md text-sm font-medium"
                                    )}
                                >
                                    {tab.name}
                                </Button>
                            ))}
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    {/* Tabs for Datasets and Documentation */}
                    <div className="sm:hidden mb-4">
                        <select
                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            value={currentTab}
                            onChange={(e) => setCurrentTab(e.target.value)}
                        >
                            {tabs.map((tab) => (
                                <option key={tab.name} value={tab.name}>{tab.name}</option>
                            ))}
                        </select>
                    </div>

                    {currentTab === "Datasets" && (
                        filteredDatasets.length > 0 ? (
                            <>
                                <ul className="divide-y divide-gray-200">
                                    {paginatedDatasets.map((dataset, index) => (
                                        <li key={index} className="flex items-center justify-between p-4 bg-white rounded-md shadow-sm mb-2">
                                            <div className="flex items-center">
                                                <VscDatabase className="mr-3 text-2xl text-slate-500" />
                                                <div>
                                                    <Link
                                                        to={`${prefixUrl}/assets/bigquery/projects/${projectId}/datasets/${dataset}`}
                                                        className="text-sm text-gray-800 hover:text-slate-600 hover:underline"
                                                    >
                                                        {dataset}
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                {/* Pagination Controls */}
                                <div className="flex justify-center mt-6 space-x-2">
                                    <Button
                                        variant="ghost"
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange('prev')}
                                        className={cn(
                                            "px-3 py-2 rounded-md border border-gray-300 text-sm font-medium",
                                            currentPage === 1 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
                                        )}
                                        aria-label="Previous Page"
                                    >
                                        Previous
                                    </Button>
                                    <Button
                                        variant="ghost"
                                        disabled={currentPage === totalPages || totalPages === 0}
                                        onClick={() => handlePageChange('next')}
                                        className={cn(
                                            "px-3 py-2 rounded-md border border-gray-300 text-sm font-medium",
                                            currentPage === totalPages || totalPages === 0 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
                                        )}
                                        aria-label="Next Page"
                                    >
                                        Next
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <p className="text-gray-600 text-sm mt-2">No datasets found for {projectId}.</p>
                        )
                    )}
                    {currentTab === "Documentation" && (
                        projectInfo && (
                            <div>
                                <ReactQuill
                                    value={projectInfo.description || "No documentation available."}
                                    readOnly={true}
                                    theme={"snow"}
                                    modules={{ toolbar: false }}
                                    className="text-sm"
                                />
                            </div>
                        )
                    )}
                </CardContent>
            </Card>
        </div>
    )
    };
