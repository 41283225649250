// components/tasks/TaskRunDetail.jsx

import { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  SheetFooter,
  SheetClose,
} from "@/components/ui/sheet";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Loader2 } from "lucide-react";

const TaskRunDetail = ({ run, onClose }) => {
  const [loading, setLoading] = useState(true);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    try {
      return format(new Date(dateString), "MMM d, yyyy HH:mm:ss");
    } catch {
      return "Invalid date";
    }
  };

  useEffect(() => {
    // Simulate loading data
    setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the delay as needed
  }, []);

  return (
    <Sheet open={true} onOpenChange={onClose}>
      <SheetContent position="right" size="lg">
        <SheetHeader>
          <SheetTitle>Run Details</SheetTitle>
          <SheetDescription>
            Details for run {run?.id_dag_task_run}
          </SheetDescription>
        </SheetHeader>
        <div className="p-4">
          {loading ? (
            <div className="flex justify-center items-center h-32">
              <Loader2 className="animate-spin h-8 w-8 text-indigo-600" />
            </div>
          ) : (
            <Accordion type="single" collapsible defaultValue="details">
              <AccordionItem value="details">
                <AccordionTrigger>Details</AccordionTrigger>
                <AccordionContent>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Status
                      </label>
                      <div className="mt-1">
                        <Badge variant="outline">{run.status}</Badge>
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Message
                      </label>
                      <div className="mt-1 text-sm text-gray-700">
                        {run.error_msg || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Scheduled At
                      </label>
                      <div className="mt-1 text-sm text-gray-700">
                        {formatDate(run.scheduled_ts)}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Start Date
                      </label>
                      <div className="mt-1 text-sm text-gray-700">
                        {formatDate(run.start_ts)}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        End Date
                      </label>
                      <div className="mt-1 text-sm text-gray-700">
                        {formatDate(run.end_ts)}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Workflow ID
                      </label>
                      <div className="mt-1 text-sm text-gray-700">
                        {run.workflow_id || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Workflow Run ID
                      </label>
                      <div className="mt-1 text-sm text-gray-700">
                        {run.workflow_run_id || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Attempt
                      </label>
                      <div className="mt-1 text-sm text-gray-700">
                        {run.attempt || 1}
                      </div>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="logs">
                <AccordionTrigger>Logs</AccordionTrigger>
                <AccordionContent>
                  <div className="text-sm text-gray-600">
                    {/* Replace this with actual logs when available */}
                    The logs will come here.
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          )}
        </div>
        <SheetFooter>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </SheetFooter>
        <SheetClose asChild>
          <Button
            variant="ghost"
            className="absolute top-2 right-2"
            onClick={onClose}
          >
            Close
          </Button>
        </SheetClose>
      </SheetContent>
    </Sheet>
  );
};

export default TaskRunDetail;
