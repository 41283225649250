import {fetchData} from './baseRequest';

export const search = async (options = {}) => {
    const params = new URLSearchParams();
    
    Object.entries(options).forEach(([key, value]) => {
        if (value && value !== 'null' && value !== undefined) {
            if (Array.isArray(value)) {
                value.forEach(v => params.append(key, v));
            } else {
                params.append(key, value);
            }
        }
    });

    const queryString = params.toString();
    const queryUrl = `/search/${queryString ? `?${queryString}` : ''}`;

    return fetchData(queryUrl);
};

export const autocompleteSearch = async (query, size = 8) => {
    const params = new URLSearchParams({ query, size });
    const queryUrl = `/search/autocomplete?${params.toString()}`;
    
    return fetchData(queryUrl);
};

