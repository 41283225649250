import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useParams, Link } from 'react-router-dom';
import BqAssetDatasetList from './BqAssetDatasetList';
import BqAssetProject from './BqAssetProject';
import BqAssetProjectList from './BqAssetProjectList';
import BqAssetTable from './BqAssetTable';
import BqAssetTableList from './BqAssetTableList';



const BqAsset = () => {

  const { domainId, projectId, datasetId, tableId } = useParams();

  return (
    <>
      <Breadcrumb />
      {!projectId && !datasetId && !tableId && <BqAssetProjectList />}
      {projectId && !datasetId && !tableId && <BqAssetDatasetList />}
      {projectId && datasetId && !tableId && <BqAssetTableList />}
      {projectId && datasetId && tableId && <BqAssetTable />}
    </>
  );
}

function Breadcrumb() {
  const { domainId, projectId, datasetId, tableId } = useParams();

  const items = [
    {
      name: <img
        src="https://global-uploads.webflow.com/5f8b0a1abe69652278dad51c/60ad30603ce79d2498f80739_google%20bigquery%20logo.png"
        alt="Home"
        width="30"
        height="30"
      />,
      href: `/domains/${domainId}/assets/bigquery/`
    },
    { name: projectId, href: `/domains/${domainId}/assets/bigquery/projects/${projectId}` },
    { name: datasetId, href: `/domains/${domainId}/assets/bigquery/projects/${projectId}/datasets/${datasetId}` },
    { name: tableId, href: `/domains/${domainId}/assets/bigquery/projects/${projectId}/datasets/${datasetId}/tables/${tableId}` }
  ].filter(item => item.name);

  return (
    <nav className="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {items.map((item, index) => (
          <li key={index}>
            <div className="flex items-center cursor-pointer">
              {index !== 0 && <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />}
              <Link to={item.href} className="text-sm font-medium text-gray-600 hover:text-gray-700 cursor-pointer">
                {item.icon ? item.icon : item.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}


export default BqAsset;
