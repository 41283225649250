import { createData, deleteData, fetchData, updateData } from "./baseRequest";

export const getUserConversations = async (userId) => {
  return fetchData(`/conversations/`);
};

export const createConversation = async () => {
  return createData(`/conversations/`);
};

export const getConversationMessages = async (conversationId) => {
  return fetchData(`/conversations/${conversationId}/messages/`);
};

export const addMessage = async (conversationId, content, sender) => {
  return createData(`/conversations/${conversationId}/messages/`, {
    content,
    sender,
  });
};

export const getConversation = async (conversationId) => {
  return fetchData(`/conversations/${conversationId}`);
};

export const getSummary = async (conversationId) => {
  return fetchData(`/conversations/${conversationId}/summary`);
};

export const generateSummary = async (conversationId, message) => {
  const data = { content: message };
  return updateData(`/conversations/${conversationId}/summary`, data);
};

export const addResource = async (conversationId, resourceId) => {
  return createData(`/conversations/${conversationId}/resources/${resourceId}`);
};

export const removeResource = async (conversationId, resourceId) => {
  return deleteData(
    `/conversations/${conversationId}/resources/${resourceId}/`
  );
};

export const searchResources = async (query, filter, facetFields, rows, useVector = false) => {
  const params = new URLSearchParams();
  if (query) params.append("query", query);
  if (rows) params.append("rows", rows.toString());
  params.append("use_vector", useVector.toString());

  if (filter && Array.isArray(filter) && filter.length > 0) {
    filter.forEach((f) => params.append("filter", f));
  }

  if (facetFields && Array.isArray(facetFields) && facetFields.length > 0) {
    facetFields.forEach((f) => params.append("facet_fields", f));
  }

  return fetchData(`/search?${params}`);
};
