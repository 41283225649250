import React, { useState, useCallback, useEffect } from 'react';
import { Search, Database, Table, FolderOpen, Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import {
    Dialog,
    DialogContent,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Kbd } from "@/components/ui/kbd";
import { cn } from "@/lib/utils";
import { autocompleteSearch } from '@/utils/search';
import { useToast } from "@/components/hooks/use-toast";
import { addResource, createConversation } from '@/utils/chat';


const RESOURCE_TYPES = {
    PROJECT: 'project',
    DATASET: 'dataset',
    TABLE: 'table',
};

const ResourceIcon = ({ type }) => {
    switch (type) {
        case RESOURCE_TYPES.PROJECT:
            return <FolderOpen className="h-5 w-5" />;
        case RESOURCE_TYPES.DATASET:
            return <Database className="h-5 w-5" />;
        case RESOURCE_TYPES.TABLE:
            return <Table className="h-5 w-5" />;
        default:
            return <Search className="h-5 w-5" />;
    }
};

const ChatResourceSearch = ({
    open,
    onOpenChange,
    conversationId,
    onResourceAdd,
    onNewConversation,
    resources = []
}) => {

    const [value, setValue] = useState("");
    const [searchResults, setSearchResults] = useState({ projects: [], tables: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [isAddingResource, setIsAddingResource] = useState(false);
    const { toast } = useToast(); // For notifications

    const flattenedResults = [...searchResults.projects, ...searchResults.tables];

    useEffect(() => {
        if (open) {
            setValue("");
            setSearchResults({ projects: [], tables: [] });
            setSelectedIndex(-1);
        }
    }, [open]);

    const handleSearch = useCallback(async (searchValue) => {
        if (!searchValue.trim()) {
            setSearchResults({ projects: [], tables: [] });
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        try {
            const results = await autocompleteSearch(searchValue);

            // Filter out already added resources
            const filteredResults = results.filter(result =>
                !resources.some(r => (r.id_resource === result.id || r.id === result.id))
            );

            const organized = {
                projects: filteredResults.filter(r => r.resource_type === 'project'),
                tables: filteredResults.filter(r => r.resource_type === 'table')
            };

            setSearchResults(organized);
            setSelectedIndex(0);
        } catch (error) {
            console.error('Search error:', error);
            setSearchResults({ projects: [], tables: [] });
        } finally {
            setIsLoading(false);
        }
    }, [resources]);

    const debouncedSearch = useCallback(debounce(handleSearch, 300), [handleSearch]);

    const handleSelect = async (resource) => {
        setIsAddingResource(true);
        try {
            let currentConversationId = conversationId;

            // Create new conversation if none exists
            if (!currentConversationId) {
                const newConversation = await createConversation();
                currentConversationId = newConversation.id_conversation;
                if (onNewConversation) {
                    onNewConversation(newConversation);
                }
            }

            await addResource(
                currentConversationId,
                resource.id_resource || resource.id
            );

            // Notify parent component to update resources
            if (onResourceAdd) {
                onResourceAdd(resource);
            }

            // Close dialog and show success message
            onOpenChange(false);
            toast({
                title: "Resource added",
                description: `Successfully added ${resource.resource_name} to the conversation`,
            });
        } catch (error) {
            console.error('Error adding resource:', error);
            toast({
                title: "Error",
                description: "Failed to add resource. Please try again.",
                variant: "destructive",
            });
        } finally {
            setIsAddingResource(false);
        }
    };

    // Keyboard navigation
    useEffect(() => {
        if (!open) return;

        const handleKeyDown = (e) => {
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    setSelectedIndex(prev =>
                        prev < flattenedResults.length - 1 ? prev + 1 : 0
                    );
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setSelectedIndex(prev =>
                        prev > 0 ? prev - 1 : flattenedResults.length - 1
                    );
                    break;
                case 'Enter':
                    e.preventDefault();
                    if (selectedIndex >= 0 && selectedIndex < flattenedResults.length) {
                        handleSelect(flattenedResults[selectedIndex]);
                    }
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [open, flattenedResults, selectedIndex]);

    return (
        <>
            <Button
                variant="outline"
                className="relative font-normal h-9 w-9 p-0 xl:h-10 xl:w-60 xl:justify-start xl:px-3 xl:py-2"
                onClick={() => onOpenChange(true)}
            >
                <Search className="h-4 w-4 xl:mr-2" />
                <span className="hidden xl:inline-flex">Search resources...</span>
                <span className="sr-only font-light">Search resources</span>
            </Button>
            <Dialog open={open} onOpenChange={onOpenChange}>
                <DialogContent className="gap-0 p-0 outline-none max-w-3xl">
                    <div className="flex items-center border-b px-4">
                        <Search className="mr-2 h-5 w-5 shrink-0 opacity-50" />
                        <input
                            className="flex h-14 w-full rounded-md bg-transparent py-3 text-base outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                            placeholder="Search resources..."
                            value={value}
                            onChange={(e) => {
                                setValue(e.target.value);
                                debouncedSearch(e.target.value);
                            }}
                        />
                        <Kbd className="hidden lg:inline-flex">esc</Kbd>
                    </div>
                    <div className="max-h-[60vh] overflow-y-auto">
                        {isLoading ? (
                            <div className="flex items-center justify-center p-4">
                                <Loader2 className="h-5 w-5 animate-spin" />
                            </div>
                        ) : (
                            <div className="px-2 py-3">
                                {searchResults.projects.length > 0 && (
                                    <div className="mb-4">
                                        <div className="mb-2 px-2 text-base font-medium text-muted-foreground">
                                            Projects
                                        </div>
                                        {searchResults.projects.map((result, idx) => (
                                            <button
                                                key={result.id}
                                                className={cn(
                                                    "flex w-full items-center gap-2 rounded-sm px-3 py-2 text-base hover:bg-accent relative",
                                                    idx === selectedIndex && "bg-accent"
                                                )}
                                                onClick={() => handleSelect(result)}
                                                disabled={isAddingResource}
                                            >
                                                <ResourceIcon type={RESOURCE_TYPES.PROJECT} />
                                                <span className="flex-1 truncate">{result.resource_name}</span>
                                                {isAddingResource && idx === selectedIndex && (
                                                    <Loader2 className="h-4 w-4 animate-spin ml-2" />
                                                )}
                                            </button>
                                        ))}
                                    </div>
                                )}

                                {searchResults.tables.length > 0 && (
                                    <div>
                                        <div className="mb-2 px-2 text-base font-medium text-muted-foreground">
                                            Tables
                                        </div>
                                        {searchResults.tables.map((result, idx) => (
                                            <button
                                                key={result.id}
                                                className={cn(
                                                    "flex w-full items-center gap-2 rounded-sm px-3 py-2 text-base hover:bg-accent relative",
                                                    idx + searchResults.projects.length === selectedIndex && "bg-accent"
                                                )}
                                                onClick={() => handleSelect(result)}
                                                disabled={isAddingResource}
                                            >
                                                <ResourceIcon type={RESOURCE_TYPES.TABLE} />
                                                <span className="flex-1 truncate">{result.resource_name}</span>
                                                {isAddingResource && idx + searchResults.projects.length === selectedIndex && (
                                                    <Loader2 className="h-4 w-4 animate-spin ml-2" />
                                                )}
                                            </button>
                                        ))}
                                    </div>
                                )}

                                {!isLoading && value && searchResults.projects.length === 0 && searchResults.tables.length === 0 && (
                                    <div className="p-4 text-center text-base text-muted-foreground">
                                        No results found.
                                    </div>
                                )}

                                {!value && (
                                    <div className="p-4 text-center text-base text-muted-foreground">
                                        Search for projects, datasets, or tables...
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ChatResourceSearch;