import React, { useState, useEffect, useCallback } from 'react';
import {
  ChevronRight,
  MoreVertical,
  Users,
  Plus,
  Building2,
  UserPlus,
  FolderPlus,
} from 'lucide-react';
import debounce from 'lodash.debounce';

import TeamManager from '../../components/modals/TeamManager';
import UserManager from '../../components/modals/UserManager';
import {
  addDomain,
  addMemberToDomain,
  getDomains,
} from '../../utils/domain';
import MemberList from '../../components/domain/MemberList';
import { cn } from "@/lib/utils"

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import { Alert, AlertDescription } from '@/components/ui/alert';
import { Skeleton } from '@/components/ui/skeleton';

function TeamSettings() {
  const [domains, setDomains] = useState({ parents: [], subdomains: [] });
  const [filteredDomains, setFilteredDomains] = useState({ parents: [], subdomains: [] });
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [collapsed, setCollapsed] = useState({});
  const [memberRefreshTrigger, setMemberRefreshTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch domains from API
  const fetchDomains = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const allDomains = await getDomains();
      const parentDomains = allDomains.filter((domain) => domain.id_parent_domain === null);
      const subDomains = allDomains.filter((domain) => domain.id_parent_domain !== null);

      const newCollapsed = parentDomains.reduce(
        (acc, parent) => ({
          ...acc,
          [parent.id_domain]: true,
        }),
        {}
      );

      setDomains({ parents: parentDomains, subdomains: subDomains });
      setFilteredDomains({ parents: parentDomains, subdomains: subDomains });
      setCollapsed(newCollapsed);
    } catch (err) {
      console.error('Error fetching domains:', err);
      setError('Failed to load domains. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDomains();
  }, [fetchDomains]);

  // Toggle collapse state
  const toggleCollapse = (id) => {
    if (hasSubdomains(id)) {
      setCollapsed((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  // Check if a domain has subdomains
  const hasSubdomains = (parentId) => {
    return domains.subdomains.some((sd) => sd.id_parent_domain === parentId);
  };

  // Create a new domain
  const createDomain = async (domainData) => {
    try {
      setError(null);
      await addDomain(JSON.stringify(domainData));
      await fetchDomains();
      setShowTeamModal(false);
    } catch (error) {
      console.error('Error creating domain:', error);
      setError('Failed to create domain. Please try again.');
    }
  };

  // Add a member to a domain
  const addMember = async (member) => {
    if (!selectedDomain) return;

    const userData = {
      user_email: member.user_email,
      id_domain: selectedDomain.id_domain,
      role_name: member.role,
    };

    try {
      setError(null);
      await addMemberToDomain(userData);
      setMemberRefreshTrigger((prev) => !prev);
      setShowUserModal(false);
    } catch (error) {
      console.error('Error adding member:', error);
      setError('Failed to add member. Please try again.');
    }
  };

  // DomainItem component
  const DomainItem = ({ domain, isSubdomain = false }) => (
    <div
      className={cn(
        "group relative flex items-center justify-between p-2 rounded-md transition-colors",
        selectedDomain?.id_domain === domain.id_domain
          ? "bg-primary/10"
          : "hover:bg-accent",
        "cursor-pointer"
      )}
      onClick={() => setSelectedDomain(domain)}
    >
      <div className="flex items-center gap-2">
        {!isSubdomain && hasSubdomains(domain.id_domain) && (
          <Button
            variant="ghost"
            size="sm"
            className="h-6 w-6 p-0"
            onClick={(e) => {
              e.stopPropagation();
              toggleCollapse(domain.id_domain);
            }}
          >
            <ChevronRight
              className={cn(
                "h-4 w-4 transition-transform",
                !collapsed[domain.id_domain] && "rotate-90"
              )}
            />
          </Button>
        )}
        <div className="flex flex-col">
          <span className="font-medium text-sm">{domain.id_domain}</span>
          <div className="flex items-center gap-1 text-muted-foreground">
            <Users className="h-3 w-3" />
            <span className="text-xs">{domain.user_count} Members</span>
          </div>
        </div>
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="h-8 w-8 p-0 opacity-0 group-hover:opacity-100"
            onClick={(e) => e.stopPropagation()}
          >
            <MoreVertical className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={() => {
              setSelectedDomain(domain);
              setShowUserModal(true);
            }}
          >
            <UserPlus className="h-4 w-4 mr-2" />
            Add Member
          </DropdownMenuItem>
          {!isSubdomain && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  setSelectedDomain(domain);
                  setShowTeamModal(true);
                }}
              >
                <FolderPlus className="h-4 w-4 mr-2" />
                Add Subdomain
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );

  // Loading Skeleton
  const DomainLoadingSkeleton = () => (
    <div className="space-y-2">
      {[1, 2, 3].map((n) => (
        <div key={n} className="flex items-center gap-2">
          <Skeleton className="h-6 w-6 rounded-md" />
          <div className="flex-1 space-y-1">
            <Skeleton className="h-4 w-3/4" />
            <Skeleton className="h-3 w-1/2" />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="p-4">
      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="grid grid-cols-4 gap-6">
        {/* Domains Section */}
        <Card className="col-span-1">
          <CardHeader className="space-y-1">
            <div className="flex items-center justify-between">
              <CardTitle className="text-lg">Domains</CardTitle>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      size="sm"
                      onClick={() => {
                        setSelectedDomain(null);
                        setShowTeamModal(true);
                      }}
                    >
                      <Plus className="h-4 w-4 mr-1" />
                      Add Domain
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    Create a new domain
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </CardHeader>
          <CardContent>
            {isLoading ? (
              <DomainLoadingSkeleton />
            ) : (
              <ScrollArea className="h-[calc(100vh-16rem)] pr-2">
                <div className="space-y-2">
                  {filteredDomains.parents.map((parent) => (
                    <div key={parent.id_domain} className="space-y-1">
                      <DomainItem domain={parent} />
                      {hasSubdomains(parent.id_domain) && !collapsed[parent.id_domain] && (
                        <div className="ml-4 pl-2 border-l space-y-1">
                          {filteredDomains.subdomains
                            .filter((sd) => sd.id_parent_domain === parent.id_domain)
                            .map((subdomain) => (
                              <DomainItem
                                key={subdomain.id_domain}
                                domain={subdomain}
                                isSubdomain
                              />
                            ))}
                        </div>
                      )}
                    </div>
                  ))}
                  {filteredDomains.parents.length === 0 && (
                    <div className="text-center text-muted-foreground">
                      No domains found.
                    </div>
                  )}
                </div>
              </ScrollArea>
            )}
          </CardContent>
        </Card>

        {/* Members Section */}
        <Card className="col-span-3">
          <CardHeader className="space-y-1">
            <div className="flex items-center justify-between">
              <CardTitle className="text-lg">Members</CardTitle>
              {selectedDomain && (
                <Button
                  size="sm"
                  onClick={() => setShowUserModal(true)}
                >
                  <UserPlus className="h-4 w-4 mr-1" />
                  Add Member
                </Button>
              )}
            </div>
            <CardDescription className="text-sm">
              {selectedDomain
                ? `Manage members of ${selectedDomain.id_domain}`
                : 'Select a domain to view members'}
            </CardDescription>
          </CardHeader>
          <CardContent>
            {selectedDomain ? (
              <MemberList
                domain={selectedDomain.id_domain}
                memberRefreshTrigger={memberRefreshTrigger}
              />
            ) : (
              <div className="flex flex-col items-center justify-center py-12 text-center">
                <Building2 className="h-8 w-8 text-muted-foreground mb-2" />
                <h3 className="text-md font-medium">No domain selected</h3>
                <p className="text-sm text-muted-foreground">
                  Select a domain from the left to view and manage its members
                </p>
              </div>
            )}
          </CardContent>
        </Card>
      </div>

      {showTeamModal && (
        <TeamManager
          open={showTeamModal}
          onClose={() => {
            setShowTeamModal(false);
            // Reset selected domain if adding a new domain
            if (!selectedDomain) setSelectedDomain(null);
          }}
          onSave={async (domainData) => {
            await createDomain(domainData);
            setShowTeamModal(false);
          }}
          parentDomainId={selectedDomain?.id_domain}
        />
      )}

      {showUserModal && selectedDomain && (
        <UserManager
          open={showUserModal}
          onClose={() => {
            setShowUserModal(false);
          }}
          onSave={async (member) => {
            await addMember(member);
            setShowUserModal(false);
          }}
          teamID={selectedDomain.id_domain}
          initialUser={null}
          initialRole=""
        />
      )}
    </div>
 
  );
}

export default TeamSettings;