import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext, useAuth } from '../context/AuthContext';

const ProtectedRoute = () => {
    const { user, isLoading } = useContext(AuthContext);

    if (isLoading) {
        return <div style={{ visibility: 'hidden' }}></div>; // Hidden or subtle indicator
    }

    if (!user) {
        console.log("1")
        console.log(isLoading)
        console.log('Redirecting due to missing or invalid user data...');
        return <Navigate to="/login" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
