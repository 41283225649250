import { createData, fetchData} from './baseRequest';

export const updateDescription = async (resource_id, description_payload) => {
    return createData(`/resources/${resource_id}/description`, description_payload);
};

export const fetchDocumentation = async (resource_id) => {
    return fetchData(`/resources/${resource_id}/description`);
};

export const addRole = async (role_payload) => {
    return createData(`/resource_role/`, role_payload);
};

export const fetchResource = async (resource_type, resource_id) => {
    return fetchData(`/resources/${resource_type}/${resource_id}`);
};

export const fetchOperators = async (resource_type, resource_id) => {
    return fetchData(`/tasks/`);
};

export const fetchTableAllColumnTags= async (projectId, datasetId, tableId) => {
    return fetchData(`/tags/all-columns-tags/${projectId}/${datasetId}/${tableId}`);
};

export const fetchOperatorsInfo = async (task_type) => {
    return fetchData(`/operators/${task_type}`);
};

export const getDatasetsOfProject = async (project_id) => {
    return fetchData(`/resources/projects/${project_id}/datasets`);
};

export const getTablesOfDataset = async (project_id, dataset_id) => {
    return fetchData(`/resources/tables/${project_id}/${dataset_id}`);
};

export const fetchDatasetInfo = async (project_id, dataset_id) => {
    return fetchData(`/resources/datasets/${project_id}/${dataset_id}`);
};

export const fetchProjectInfo = async (task_type) => {
    return fetchData(`/tasks/${task_type}`);
};

export const fetchProjects = async () => {
    return fetchData(`/resources/projects`);
};

export const fetchTableInfo = async (project_id, dataset_id) => {
    return fetchData(`/resources/tables/${project_id}/${dataset_id}`);
};


