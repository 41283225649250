import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition, Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'


function DomainManager({ onClose, onSave }) {
    const [name, setName] = useState("")
    const [owner, setOwner] = useState("")
    const [users, setUsers] = useState([])


    const fetchUsers = async () => {
        try {
            const response = await fetch("http://localhost:8080/user/", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const users = await response.json();

            setUsers(users)

        } catch (error) {
            console.error("There was a problem creating the domain:", error);
            throw error;
        }
    };

    useEffect(() => {
        fetchUsers()
    }, []);

    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={onClose}
            >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div className="inline-block w-1/2 max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6  mb-6"
                        >
                            Domain
                        </Dialog.Title>

                        <div className='mb-6'>
                            <input
                                type="text"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder="New domain"
                                className="block mb-2 w-full rounded-md border-0 py-1.5  text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                        </div>
                        <div className="mt-2">
                            <label
                                className="block text-sm font-medium leading-6 text-gray-600"
                            >
                                <span>Owner</span>
                            </label>

                            <Listbox as="div" className="space-y-4" value={owner.name} onChange={value => { setOwner(value.name); console.log("Selected owner:", value) }}>
                                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    <span className="block truncate">choose a user</span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="mt-2 space-y-2 border border-gray-300 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                        {users.map(user => (
                                            <Listbox.Option
                                                key={user.user_email}
                                                value={user}
                                                className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : ' text-gray-600'
                                                    }`
                                                }
                                            >
                                                {user.user_email}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </Listbox>
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button
                                type="button"
                                className="text-sm font-semibold leading-6  text-gray-800"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                            <div>
                                <button
                                    type="submit"
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={() => {
                                        onSave({ "name": name, "owner": owner });
                                        onClose();
                                    }}
                                >
                                    Save
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default DomainManager;
