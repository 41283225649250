import React, { useState, useEffect, useCallback, memo } from 'react';
import { PencilIcon } from 'lucide-react';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { updateDescription } from '../../utils/resources';

// API function to update description


// Memoize the Modal component
const Modal = memo(({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose}></div>
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle">&#8203;</span>
        <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 sm:align-middle">
          {children}
        </div>
      </div>
    </div>
  );
});

// Memoize the Editor component
const Editor = memo(({ value, onChange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      ['link', 'blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }]
    ],
    keyboard: {
      bindings: {
        tab: false,
        'shift+tab': false
      }
    }
  };

  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      theme="snow"
      modules={modules}
      className="h-64 mb-12"
      preserveWhitespace={true}
    />
  );
});

const TaskDocumentation = (props) => {
  const { taskId } = useParams(); // Get dagId from URL params
  const [loading, setLoading] = useState(false);
  const [documentation, setDocumentation] = useState(props.documentation);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempDocumentation, setTempDocumentation] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const handleOpenModal = useCallback(() => {
    setTempDocumentation(documentation);
    setIsModalOpen(true);
    setIsEditing(true);
    setError(null);
  }, [documentation]);

  const handleSaveDocumentation = useCallback(async () => {
    if (!taskId) return;
    
    setIsSaving(true);
    setError(null);
    
    try {
      const body = JSON.stringify({
        description: tempDocumentation
    })
      await updateDescription(taskId, body);
      setDocumentation(tempDocumentation);
      setIsModalOpen(false);
      setIsEditing(false);
    } catch (error) {
      setError('Failed to save documentation. Please try again.');
    } finally {
      setIsSaving(false);
    }
  }, [taskId, tempDocumentation]);

  const handleEditorChange = useCallback((content) => {
    if (isEditing) {
      setTempDocumentation(content);
    }
  }, [isEditing]);

  const getFirstHundredChars = useCallback((text) => {
    const strippedText = text.replace(/<[^>]+>/g, '');
    return strippedText.length > 100
      ? `${strippedText.substring(0, 100)}...`
      : strippedText;
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setIsEditing(false);
    setError(null);
  }, []);

  if (loading) {
    return (
      <div className="mx-auto px-4 sm:px-6 lg:px-8 mt-2">
        <div className="max-w-lg animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-20 bg-gray-200 rounded w-full"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 mt-2">
      <div className="max-w-lg">
        <div className="mt-4 border-b pb-4">
          <h3 className="font-medium text-gray-800 mb-2">Documentation</h3>
          {documentation ? (
            <div className="mt-2">
              <p
                className="text-sm mb-1"
                dangerouslySetInnerHTML={{ __html: documentation }}
              />
              <button
                type="button"
                className="border-2 group flex items-center rounded-md bg-white p-1 pr-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={handleOpenModal}
              >
                <span className="flex h-6 w-6 items-center justify-center border-gray-300 text-gray-400">
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </span>
                <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-500 ml-2">
                  edit description
                </span>
              </button>
            </div>
          ) : (
            <div className="mt-2 flex flex-col items-start">
              <span className="text-sm text-gray-400 mb-2">
                No documentation yet. Share your knowledge by adding documentation and links to helpful resources.
              </span>
              <button
                type="button"
                className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-100"
                onClick={handleOpenModal}
              >
                <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
                  + add documentation
                </span>
              </button>
            </div>
          )}
        </div>

        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <div className="sm:flex sm:items-start">
            <div className="mt-3 w-full">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                Edit Documentation
              </h3>
              {error && (
                <div className="mb-4 p-2 text-sm text-red-600 bg-red-50 rounded-md">
                  {error}
                </div>
              )}
              <Editor
                value={tempDocumentation}
                onChange={handleEditorChange}
              />
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50"
              onClick={handleSaveDocumentation}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={handleCloseModal}
              disabled={isSaving}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default TaskDocumentation;