import { createData, deleteData, fetchData, updateData } from "./baseRequest";

export const saveIngestionConfig = async (data) => {
  return createData(`/ingestion/save-config`, data);
};

export const getIngestionConfigurations = async () => {
  return fetchData(`/ingestion/configurations`);
};

export const updateIngestionConfig = async (configId, data) => {
  return updateData(`/ingestion/update-config/${configId}`, data);
};

export const deleteIngestionConfig = async (configId) => {
  return deleteData(`/ingestion/delete-config/${configId}`);
};

export const getConfigFields = async (type) => {
  return fetchData(`/ingestion/config-fields/${type}`);
};