import React, { useState } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import DomainManager from '../../components/modals/DomainManager';
import { useEffect } from 'react';
import TeamManager from '../../components/modals/TeamManager';
import UserManager from '../../components/modals/UserManager';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function AccessSettings() {
    const [projects, setProjects] = useState([]);
    const [domains, setDomains] = useState([]);
    const [teams, setTeams] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [showProjectModal, setShowProjectModal] = useState(false)
    const [showTeamModal, setShowTeamModal] = useState(false)
    const [showUserModal, setShowUserModal] = useState(false)

    const createDomain = async (domainData) => {
        try {
            const response = await fetch("http://localhost:8080/currdunit/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(domainData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            // await createTeam({ "name": domainData.name, "spokes_person": domainData.owner, "id_curr_dunit": result.id_curr_dunit })

            setDomains([...domains, domainData])
            fetchDomains()
            return result;

        } catch (error) {
            console.error("There was a problem creating the domain:", error);
            throw error;
        }
    };

    const createTeam = async (domainData) => {
        try {
            const response = await fetch("http://localhost:8080/dunit/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(domainData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            setTeams([...teams, domainData])
            fetchDomainTeams()
            return result;

        } catch (error) {
            console.error("There was a problem creating the domain:", error);
            throw error;
        }

    };

    const fetchDomainTeams = async () => {
        try {
            const response = await fetch("http://localhost:8080/dunit/" + selectedDomain.id_curr_dunit, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const teams = await response.json();

            setTeams(teams)

        } catch (error) {
            console.error("There was a problem creating the domain:", error);
            throw error;
        }

    };

    const fetchDomains = async () => {
        try {
            const response = await fetch("http://localhost:8080/currdunit/", {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch task type info");
            }
            const domains = await response.json();
            setDomains(domains)
        } catch (error) {
            console.error("Error fetching task type info:", error);
        }
    };

    const fetchTeamMembers = async () => {
        try {
            const response = await fetch("http://localhost:8080/user/dunit/" + selectedTeam.id_dunit, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch task type info");
            }
            const members = await response.json();
            setTeamMembers(members)
        } catch (error) {
            console.error("Error fetching task type info:", error);
        }
    };


    const addMember = async (member) => {

        try {
            const response = await fetch("http://localhost:8080/user/" + member.id_user + "/" + selectedTeam.id_dunit, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ role: member.role })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const member_res = await response.json();

            setTeamMembers([...teamMembers, member])
            return member_res;

        } catch (error) {
            console.error("There was a problem creating the domain:", error);
            throw error;
        }
    };

    const addMembers = async (member) => {
        try {
            await addMember(member);
        } catch (error) {
            console.error("There was a problem adding one or more members:", error);
        }
    };

    useEffect(() => {
        fetchDomains()
    }, []);

    useEffect(() => {
        fetchDomainTeams()
        setTeamMembers([])
    }, [selectedDomain]);

    useEffect(() => {
        fetchTeamMembers()
    }, [selectedTeam]);

    return (
        <div className="min-h-screen flex">
            <div className="w-1/5 py-4 pr-6 border-r">
                <h2 className="text-md font-semibold mb-4">Access (groups, users)</h2>
                <button
                    onClick={() => setShowProjectModal(true)}
                    className="mb-4 rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                >
                    Add Access +
                </button>
                <ul>
                    {domains.map((domain, index) => (
                        <li key={domain.name}
                            onClick={() => setSelectedDomain(domain)}
                            className={`col-span-1 flex rounded-md shadow-sm mb-2 cursor-pointer 
                        ${selectedDomain?.name === domain.name ? 'bg-gray-200' : 'bg-white'}`}>
                            <div className="flex flex-1 items-center justify-between truncate rounded-md border-b border-l border-r border-t">
                                <div className="flex-1 truncate px-4 py-3 text-sm">
                                    <a href="#" className="font-medium  hover: text-gray-700">
                                        {domain.name}
                                    </a>
                                </div>
                                <div className="flex-shrink-0 pr-2">
                                    <button
                                        type="button"
                                        className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        <span className="sr-only">Open options</span>
                                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="w-1/4 py-4 px-5 border-r">
                <h2 className="text-md font-semibold mb-4">Resources</h2>
                <button
                    onClick={() => setShowTeamModal(true)}
                    className="mb-4 rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                >
                    Add Resource +
                </button>
                <div>
                    <ul role="list" className="  ">
                        {teams.map((team) => (
                            <li key={team.name}
                                onClick={() => setSelectedTeam(team)}
                                className={`col-span-1 flex rounded-md shadow-sm mb-2 cursor-pointer 
                        ${selectedTeam?.name === team.name ? 'bg-gray-200' : 'bg-white'}`}>

                                <div className="flex flex-1 items-center justify-between truncate rounded-md border-b border-l border-r border-t ">
                                    <div className="flex-1 truncate px-4 py-2 text-sm">
                                        <a href="#" className="font-medium hover: text-gray-600">
                                            {team.name}
                                        </a>
                                        <p className="text-gray-600">{team.user_count} Members</p>
                                    </div>
                                    <div className="flex-shrink-0 pr-2">
                                        <button
                                            type="button"
                                            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <span className="sr-only">Open options</span>
                                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="w-3/5 py-4 px-6">
                <h2 className="text-md font-semibold mb-4">Roles</h2>
                <button
                    onClick={() => setShowUserModal(true)}
                    className="mb-4 rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                >
                    Add Role +
                </button>
                <div>
                    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                        {teamMembers.map((person) => (
                            <li key={person.user_email} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                <div className="flex w-full items-center justify-between space-x-6 p-2">
                                    <div className="flex-1 truncate">
                                        <div className="flex items-center space-x-3">
                                            <h3 className="truncate text-sm font-medium  text-gray-800">{person.name}</h3>
                                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                "Admin"
                                            </span>
                                        </div>
                                        <p className="mt-1 truncate text-sm text-gray-600">{person.user_email}</p>
                                    </div>
                                    <img className="h-8 w-8 flex-shrink-0 rounded-full " src="https://play-lh.googleusercontent.com/cF_oWC9Io_I9smEBhjhUHkOO6vX5wMbZJgFpGny4MkMMtz25iIJEh2wASdbbEN7jseAx" alt="" />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {
                showProjectModal && (
                    <DomainManager onClose={() => setShowProjectModal(false)} onSave={(domainData) => createDomain(domainData)} />

                )
            }
            {
                showTeamModal && (
                    <TeamManager domainId={selectedDomain.id_curr_dunit} onClose={() => setShowTeamModal(false)} onSave={(teamData) => createTeam(teamData)} />
                )
            }
            {
                showUserModal && (
                    <UserManager teamId={selectedTeam.id_dunit} onClose={() => setShowUserModal(false)} onSave={(members) => addMembers(members)} />
                )
            }
        </div >
    );
}

export default AccessSettings;
