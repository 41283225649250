import React, { useState, useCallback, useEffect } from 'react';
import { Search, Database, Table, FolderOpen, Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import {
    Dialog,
    DialogContent,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Kbd } from "@/components/ui/kbd";
import { cn } from "@/lib/utils";
import { autocompleteSearch } from '@/utils/search';


const RESOURCE_TYPES = {
    PROJECT: 'project',
    DATASET: 'dataset',
    TABLE: 'table',
};

const ResourceIcon = ({ type }) => {
    switch (type) {
        case RESOURCE_TYPES.PROJECT:
            return <FolderOpen className="h-5 w-5" />;
        case RESOURCE_TYPES.DATASET:
            return <Database className="h-5 w-5" />;
        case RESOURCE_TYPES.TABLE:
            return <Table className="h-5 w-5" />;
        default:
            return <Search className="h-5 w-5" />;
    }
};

const SearchCommand = ({ open, onOpenChange }) => {
    const navigate = useNavigate();
    const [value, setValue] = useState("");
    const [searchResults, setSearchResults] = useState({ projects: [], tables: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    // Flatten results for keyboard navigation
    const flattenedResults = [
        ...searchResults.projects,
        ...searchResults.tables
    ];

    const parseResourceName = (resourceName) => {
        // Split by dots to separate project.dataset.table
        const parts = resourceName.split('.');
        
        switch (parts.length) {
            case 1:
                // Just project
                return {
                    param_project: parts[0]
                };
            case 2:
                // Project and dataset
                return {
                    param_project: parts[0],
                    param_dataset: parts[1]
                };
            case 3:
                // Project, dataset, and table
                return {
                    param_project: parts[0],
                    param_dataset: parts[1],
                    param_table: parts[2]
                };
            default:
                return {};
        }
    };

    const handleSearch = useCallback(async (searchValue) => {
        if (!searchValue.trim()) {
            setSearchResults({ projects: [], tables: [] });
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        try {
            const results = await autocompleteSearch(searchValue);

            const organized = {
                projects: results.filter(r => r.resource_type === 'project'),
                tables: results.filter(r => r.resource_type === 'table')
            };

            setSearchResults(organized);
            setSelectedIndex(0); // Reset selection to first item
        } catch (error) {
            console.error('Search error:', error);
            setSearchResults({ projects: [], tables: [] });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const debouncedSearch = useCallback(debounce(handleSearch, 300), [handleSearch]);

    // Handle keyboard navigation
    useEffect(() => {
        if (!open) return;

        const handleKeyDown = (e) => {
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    setSelectedIndex(prev =>
                        prev < flattenedResults.length - 1 ? prev + 1 : 0
                    );
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setSelectedIndex(prev =>
                        prev > 0 ? prev - 1 : flattenedResults.length - 1
                    );
                    break;
                case 'Enter':
                    e.preventDefault();
                    if (selectedIndex >= 0 && selectedIndex < flattenedResults.length) {
                        handleSelect(flattenedResults[selectedIndex]);
                    }
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [open, flattenedResults, selectedIndex]);

    const handleSelect = (result) => {
        const baseUrl = '/domains/noonbicedata/assets/bigquery/projects/';
        let url = '';

        // Parse the resource_name to get project, dataset, and table parameters
        const params = parseResourceName(result.resource_name);

        switch (result.resource_type) {
            case 'project':
                url = `${baseUrl}${params.param_project}`;
                break;
            case 'dataset':
                url = `${baseUrl}${params.param_project}/datasets/${params.param_dataset}`;
                break;
            case 'table':
                url = `${baseUrl}${params.param_project}/datasets/${params.param_dataset}/tables/${params.param_table}`;
                break;
        }

        if (url) {
            onOpenChange(false);
            navigate(url);
        }
    };

    return (
        <>
            <Button
                variant="outline"
                className="relative font-normal h-9 w-9 p-0 xl:h-10 xl:w-60 xl:justify-start xl:px-3 xl:py-2"
                onClick={() => onOpenChange(true)}
            >
                <Search className="h-4 w-4 xl:mr-2" />
                <span className="hidden xl:inline-flex">Search resources...</span>
                <span className="sr-only font-light">Search resources</span>
            </Button>

            <Dialog open={open} onOpenChange={onOpenChange}>
                <DialogContent className="gap-0 p-0 outline-none max-w-3xl">
                    <div className="flex items-center border-b px-4">
                        <Search className="mr-2 h-5 w-5 shrink-0 opacity-50" />
                        <input
                            className="flex h-14 w-full rounded-md bg-transparent py-3 text-base outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                            placeholder="Search resources..."
                            value={value}
                            onChange={(e) => {
                                setValue(e.target.value);
                                debouncedSearch(e.target.value);
                            }}
                        />
                    </div>
                    <div className="max-h-[60vh] overflow-y-auto">
                        {isLoading ? (
                            <div className="flex items-center justify-center p-4">
                                <Loader2 className="h-5 w-5 animate-spin" />
                            </div>
                        ) : (
                            <div className="px-2 py-3">
                                {searchResults.projects.length > 0 && (
                                    <div className="mb-4">
                                        <div className="mb-2 px-2 text-base font-medium text-muted-foreground">
                                            Projects
                                        </div>
                                        {searchResults.projects.map((result, idx) => (
                                            <button
                                                key={result.id}
                                                className={cn(
                                                    "flex w-full items-center gap-2 rounded-sm px-3 py-2 text-base hover:bg-accent",
                                                    idx === selectedIndex && "bg-accent"
                                                )}
                                                onClick={() => handleSelect(result)}
                                            >
                                                <ResourceIcon type={RESOURCE_TYPES.PROJECT} />
                                                <span>{result.resource_name}</span>
                                            </button>
                                        ))}
                                    </div>
                                )}

                                {searchResults.tables.length > 0 && (
                                    <div>
                                        <div className="mb-2 px-2 text-base font-medium text-muted-foreground">
                                            Tables
                                        </div>
                                        {searchResults.tables.map((result, idx) => (
                                            <button
                                                key={result.id}
                                                className={cn(
                                                    "flex w-full items-center gap-2 rounded-sm px-3 py-2 text-base hover:bg-accent",
                                                    idx + searchResults.projects.length === selectedIndex && "bg-accent"
                                                )}
                                                onClick={() => handleSelect(result)}
                                            >
                                                <ResourceIcon type={RESOURCE_TYPES.TABLE} />
                                                <span>{result.resource_name}</span>
                                            </button>
                                        ))}
                                    </div>
                                )}

                                {!isLoading && value && searchResults.projects.length === 0 && searchResults.tables.length === 0 && (
                                    <div className="p-4 text-center text-base text-muted-foreground">
                                        No results found.
                                    </div>
                                )}

                                {!value && (
                                    <div className="p-4 text-center text-base text-muted-foreground">
                                        Search for projects, datasets, or tables...
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SearchCommand;