import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { PencilIcon, PlusIcon } from '@heroicons/react/20/solid';
import ReactQuill from 'react-quill';
import TagModal from '../common/Tags';
import TextEditorModal from '../modals/TextEditorModal';
import { fetchAllTagList } from '../../utils/domain';
import { fetchData } from '../../utils/baseRequest';
import { fetchResource, updateDescription } from '../../utils/resources';
import TableLineage from '../common/TableLineage';
import { VscEdit } from "react-icons/vsc"; // Icon for editing
import { cn } from "@/lib/utils";

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";

export default function BqAssetTable() {
    const { projectId, datasetId, tableId, domainId } = useParams();

    const [showModal, setShowModal] = useState(false);
    const [editingColumnIndex, setEditingColumnIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [currentTab, setCurrentTab] = useState("Schema");
    const [tableInfo, setTableInfo] = useState(null);
    const [tags, setTags] = useState([]);
    const [showTagModal, setShowTagModal] = useState(false);
    const [editingTagColumn, setEditingTagColumn] = useState(null);
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const [showColumnDescriptionModal, setShowColumnDescriptionModal] = useState(false);
    const [editingColumnDescription, setEditingColumnDescription] = useState(null);
    const [error, setError] = useState(null);
    const params = useParams();

    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust as needed

    // Determine prefixUrl based on domainId
    const prefixUrl = domainId ? `/domains/${domainId}` : '';

    const tabs = [
        { name: 'Schema', onClick: () => setCurrentTab("Schema"), current: currentTab === 'Schema' },
        { name: 'Documentation', onClick: () => setCurrentTab("Documentation"), current: currentTab === 'Documentation' },
        { name: 'Lineage', onClick: () => setCurrentTab("Lineage"), current: currentTab === 'Lineage' }
    ];

    // Fetch all tags
    const fetchAllTags = async () => {
        try {
            const fetchedTags = await fetchAllTagList();
            setTags(fetchedTags);
        } catch (error) {
            console.error("Error fetching tags:", error);
        }
    };

    // Open Tag Modal
    const openTagModal = (columnId) => {
        setEditingTagColumn(columnId);
        setShowTagModal(true);
    };

    // Handle Tag Modal Close
    const handleOnClose = async () => {
        setShowTagModal(false);
        if (editingTagColumn) {
            await getColumns();
        }
    };

    // Fetch Columns with Metadata
    const getColumns = async () => {
        try {
            const fetchedColumns = await fetchData(`/resources/columns/${projectId}/${datasetId}/${tableId}`);
            const columnsWithMetadata = await Promise.all(fetchedColumns.columns.map(async (column) => {
                const metadata = await fetchResource('column', column.id_column);
                return { ...column, ...metadata };
            }));
            setColumns(columnsWithMetadata || []);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching columns:", error);
            setError("Failed to fetch columns.");
            setLoading(false);
        }
    };

    // Fetch Table Information
    const fetchTableInfoData = async () => {
        try {
            const data = await fetchData(`/resources/tables/${projectId}/${datasetId}/${tableId}`);
            setTableInfo(data);
        } catch (error) {
            console.error("Error fetching table info:", error);
            setError("Failed to fetch table information.");
            setLoading(false);
        }
    };

    // Fetch Table Information and Columns
    useEffect(() => {
        getColumns();
        fetchTableInfoData();
        fetchAllTags();
    }, [projectId, datasetId, tableId]);

    // Handle Opening Description Modal
    const handleOpenDescriptionModal = (content) => {
        setContent(content);
        setOpen(true);
    };

    // Handle Opening Column Description Modal
    const handleOpenColumnDescriptionModal = (columnId, description) => {
        setEditingColumnDescription({ id: columnId, description });
        setShowColumnDescriptionModal(true);
    };

    // Handle Saving Column Description
    const handleSaveColumnDescription = async (updatedContent) => {
        try {
            const body = JSON.stringify({
                description: updatedContent
            });
            await updateDescription(editingColumnDescription.id, body);
            setColumns(prevColumns => prevColumns.map(column =>
                column.id_column === editingColumnDescription.id
                    ? { ...column, description: updatedContent }
                    : column
            ));
        } catch (error) {
            console.error("Error updating the column description:", error);
            setError("Failed to update column description.");
        }
        setShowColumnDescriptionModal(false);
    };

    // Get First 100 Characters of Description
    const getFirstHundredChars = (text) => {
        if (!text) return '';
        const stripped = text.replace(/<[^>]*>/g, '');
        return stripped.length > 100 ? stripped.substr(0, 100) + '...' : stripped;
    };

    // Filtered Columns based on Search Term
    const filteredColumns = useMemo(() => {
        return columns.filter(column =>
            column.column_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (column.description && column.description.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [columns, searchTerm]);

    // Pagination Logic
    const totalPages = Math.ceil(filteredColumns.length / itemsPerPage);
    const paginatedColumns = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredColumns.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredColumns, currentPage, itemsPerPage]);

    // Handle Page Change
    const handlePageChange = (direction) => {
        if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        } else if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };

    if (loading) return <div className="py-4 text-center">Loading...</div>;
    if (error) return (
        <Alert variant="destructive" className="my-4">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
        </Alert>
    );

    return (
        <div className="min-h-screen">
            {/* Table Detail Card */}
            <Card>
                <CardHeader className="flex flex-col sm:flex-row justify-between items-center mb-4">
                    <CardTitle className="text-lg">{tableId}</CardTitle>
                    <div className="mt-4 sm:mt-0 flex flex-col sm:flex-row items-center gap-4">
                        {/* Search Input */}
                        <Input
                            type="text"
                            placeholder="Search columns..."
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setCurrentPage(1); // Reset to first page on search
                            }}
                            className="w-full sm:w-64 text-sm"
                        />
                        {/* Tab Buttons */}
                        <div className="hidden sm:flex space-x-4">
                            {tabs.map((tab) => (
                                <Button
                                    key={tab.name}
                                    variant={tab.current ? "primary" : "ghost"}
                                    onClick={tab.onClick}
                                    className={cn(
                                        tab.current ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-700",
                                        "whitespace-nowrap px-3 py-2 rounded-md text-sm font-medium"
                                    )}
                                >
                                    {tab.name}
                                </Button>
                            ))}
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    {/* Mobile Tabs */}
                    <div className="sm:hidden mb-4">
                        <select
                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            value={currentTab}
                            onChange={(e) => setCurrentTab(e.target.value)}
                        >
                            {tabs.map((tab) => (
                                <option key={tab.name} value={tab.name}>{tab.name}</option>
                            ))}
                        </select>
                    </div>

                    {/* Tag Modal */}
                    {showTagModal && (
                        <TagModal
                            resource_id={editingTagColumn}
                            onClose={handleOnClose}
                        />
                    )}

                    {/* Column Description Modal */}
                    {showColumnDescriptionModal && (
                        <TextEditorModal
                            title="Edit Column Description"
                            open={showColumnDescriptionModal}
                            setOpen={setShowColumnDescriptionModal}
                            onClose={() => setShowColumnDescriptionModal(false)}
                            onSave={handleSaveColumnDescription}
                            content={editingColumnDescription.description}
                            onContentChange={setContent}
                        />
                    )}

                    {currentTab === "Schema" && (
                        <>
                            {/* Columns Table */}
                            <div className='mb-4 overflow-x-auto'>
                                <table className="min-w-full text-sm text-left">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2 text-gray-700">Column</th>
                                            <th className="px-4 py-2 text-gray-700">Type</th>
                                            <th className="px-4 py-2 text-gray-700">Tag</th>
                                            <th className="px-4 py-2 text-gray-700">Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedColumns.map(column => (
                                            <tr key={column.column_name} className="bg-white border-b">
                                                <td className="px-4 py-2 text-sm">{column.column_name}</td>
                                                <td className="px-4 py-2 text-sm">{column.column_type}</td>
                                                <td className="px-4 py-2 text-sm">
                                                    {column.tags && column.tags.length ? (
                                                        <>
                                                            {column.tags.map(tag => (
                                                                <Badge key={tag} className="mr-2 mb-1">
                                                                    {tag}
                                                                </Badge>
                                                            ))}
                                                            <Button
                                                                variant="ghost"
                                                                size="small"
                                                                onClick={() => openTagModal(column.id_column)}
                                                                aria-label={`Edit tags for ${column.column_name}`}
                                                                className="mt-1"
                                                            >
                                                                <PencilIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <Button
                                                            variant="ghost"
                                                            size="small"
                                                            onClick={() => openTagModal(column.id_column)}
                                                            aria-label={`Add tags for ${column.column_name}`}
                                                        >
                                                            <PlusIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                                                            <span className="ml-1 text-gray-600">Add</span>
                                                        </Button>
                                                    )}
                                                </td>
                                                <td
                                                    className="px-4 py-2 text-sm cursor-pointer relative group"
                                                    onClick={() => handleOpenColumnDescriptionModal(column.id_column, column.description)}
                                                >
                                                    <span>{getFirstHundredChars(column.description)}</span>
                                                    <div className="absolute bottom-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                                        <VscEdit className="h-4 w-4 text-gray-400 hover:text-gray-600" aria-hidden="true" />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination Controls */}
                            {totalPages > 1 && (
                                <div className="flex justify-center mt-6 space-x-2">
                                    <Button
                                        variant="ghost"
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange('prev')}
                                        className={cn(
                                            "px-3 py-2 rounded-md border border-gray-300 text-sm font-medium",
                                            currentPage === 1 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
                                        )}
                                        aria-label="Previous Page"
                                    >
                                        Previous
                                    </Button>
                                    <Button
                                        variant="ghost"
                                        disabled={currentPage === totalPages || totalPages === 0}
                                        onClick={() => handlePageChange('next')}
                                        className={cn(
                                            "px-3 py-2 rounded-md border border-gray-300 text-sm font-medium",
                                            currentPage === totalPages || totalPages === 0 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
                                        )}
                                        aria-label="Next Page"
                                    >
                                        Next
                                    </Button>
                                </div>
                            )}
                        </>
                    )}

                    {currentTab === "Documentation" && tableInfo && (
                        <div className='mb-4'>
                            <ReactQuill
                                value={tableInfo.description || "No documentation available."}
                                readOnly={true}
                                theme={"snow"}
                                modules={{ toolbar: false }}
                                className="text-sm"
                            />
                        </div>
                    )}

                    {currentTab === "Lineage" && (
                        <TableLineage
                            projectId={projectId}
                            datasetId={datasetId}
                            tableId={tableId}
                        />
                    )}
                </CardContent>
            </Card>
        </div>
    );
}
