import { Fragment, useState, useEffect, useContext } from "react";
import { Search, ChevronRight, TagIcon, ChartAreaIcon, TableIcon, WorkflowIcon, ChartPieIcon } from 'lucide-react';
import { ChevronRightIcon, ChevronDownIcon, MagnifyingGlassIcon, BellIcon, Bars3Icon } from "@heroicons/react/20/solid";
import { GrDocumentTxt } from "react-icons/gr";
import { SiMicrosoftexcel, SiLooker, SiJupyter, SiGoogledatastudio } from "react-icons/si";
import { AuthContext } from "../context/AuthContext";
import SetDomainModal from "../components/modals/SetDomain";

const dummyResults = [
  { id: 1, title: 'Annual Financial Report', type: 'document', domain: 'Finance', content: 'This report contains financial data for the year 2023.', author: 'John Doe' },
  { id: 2, title: 'Customer Acquisition Strategy', type: 'presentation', domain: 'Marketing', content: 'A comprehensive strategy for acquiring new customers in Q3.', author: 'Jane Smith' },
  { id: 3, title: 'Product Roadmap 2024', type: 'spreadsheet', domain: 'Product', content: 'Detailed roadmap for product development in 2024.', author: 'Mike Johnson' },
  { id: 4, title: 'Employee Onboarding Process', type: 'workflow', domain: 'HR', content: 'Step-by-step guide for onboarding new employees.', author: 'Sarah Brown' },
  { id: 5, title: 'Q4 Sales Forecast', type: 'dashboard', domain: 'Sales', content: 'Sales projections and targets for Q4 2023.', author: 'Tom Wilson' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function TagBadge({ tag }) {
  const handleTagClick = () => {
    console.log("Tag clicked:", tag.name);
  };

  return (
    <span onClick={handleTagClick} className={`inline-flex cursor-pointer items-center px-3.5 py-1.5 rounded-full text-xs font-medium bg-gray-100 text-indigo-800 mr-2`}>
      {tag.name}
    </span>
  );
}

const EnterpriseSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState(dummyResults);
  const [facets, setFacets] = useState({ type: {}, domain: {} });
  const [selectedFacets, setSelectedFacets] = useState({ type: [], domain: [] });
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [filteredResults, setFilteredResults] = useState(dummyResults);
  const [tags, setTags] = useState([{ name: "Urgent" }, { name: "Q3" }, { name: "Strategy" }]);
  const [isLabelsExpanded, setIsLabelsExpanded] = useState(false);
  const [showSetDomainModal, setShowSetDomainModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const { user } = useContext(AuthContext);

  // Function to update facets based on results
  useEffect(() => {
    const updateFacets = () => {
      const newFacets = { type: {}, domain: {} };
      dummyResults.forEach(result => {
        newFacets.type[result.type] = (newFacets.type[result.type] || 0) + 1;
        newFacets.domain[result.domain] = (newFacets.domain[result.domain] || 0) + 1;
      });
      setFacets(newFacets);
    };

    updateFacets();
  }, []);

  // Autocomplete filtering logic
  useEffect(() => {
    if (searchTerm) {
      const autoComplete = dummyResults
        .filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase()))
        .slice(0, 5);
      setAutocompleteResults(autoComplete);
      setShowAutocomplete(true);
    } else {
      setAutocompleteResults([]);
      setShowAutocomplete(false);
    }
  }, [searchTerm]);

  // Filtering results based on search term and facets
  useEffect(() => {
    const filtered = dummyResults.filter(result => {
      const matchesSearch = searchTerm
        ? result.title.toLowerCase().includes(searchTerm.toLowerCase())
        : true;
      const matchesType = selectedFacets.type.length === 0 || selectedFacets.type.includes(result.type);
      const matchesDomain = selectedFacets.domain.length === 0 || selectedFacets.domain.includes(result.domain);
      return matchesSearch && matchesType && matchesDomain;
    });
    setFilteredResults(filtered);
  }, [searchTerm, selectedFacets]);

  // Facet click handler
  const handleFacetClick = (facetType, facetValue) => {
    const updatedFacets = { ...selectedFacets };
    const index = updatedFacets[facetType].indexOf(facetValue);
    if (index > -1) {
      updatedFacets[facetType] = updatedFacets[facetType].filter(v => v !== facetValue);
    } else {
      updatedFacets[facetType] = [...updatedFacets[facetType], facetValue];
    }
    setSelectedFacets(updatedFacets);
  };

  // Get icon for each resource type
  const getIconForType = (type) => {
    switch (type) {
      case 'document':
        return <TableIcon className="h-5 w-5 text-blue-500" />;
      case 'presentation':
        return <ChartAreaIcon className="h-5 w-5 text-green-500" />;
      case 'spreadsheet':
        return <TableIcon className="h-5 w-5 text-yellow-500" />;
      case 'workflow':
        return <WorkflowIcon className="h-5 w-5 text-purple-500" />;
      case 'dashboard':
        return <ChartPieIcon className="h-5 w-5 text-pink-500" />;
      default:
        return <TableIcon className="h-5 w-5 text-gray-500" />;
    }
  };

  // Highlight matched text in search results
  const highlightText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="bg-yellow-200">{part}</span>
      ) : part
    );
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Search box */}
      <form onSubmit={(e) => e.preventDefault()} className="mb-6 relative">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search for documents, presentations, spreadsheets..."
            className="w-full px-4 py-2 text-gray-700 bg-white border rounded-lg focus:border-blue-500 focus:outline-none focus:ring"
          />
          <button type="submit" className="absolute inset-y-0 right-0 flex items-center pr-3">
            <Search className="h-5 w-5 text-gray-400" />
          </button>
        </div>
        {showAutocomplete && autocompleteResults.length > 0 && (
          <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 shadow-lg">
            {autocompleteResults.map((result) => (
              <div
                key={result.id}
                className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
                onClick={() => {
                  setSearchTerm(result.title);
                  setShowAutocomplete(false);
                }}
              >
                {getIconForType(result.type)}
                <span className="ml-2">{highlightText(result.title, searchTerm)}</span>
              </div>
            ))}
          </div>
        )}
      </form>

      {/* Sidebar for facets */}
      <div className="flex">
        <div className="w-1/4 pr-6">
          <h3 className="text-lg font-semibold mb-2">Type</h3>
          <ul>
            {Object.entries(facets.type).map(([type, count]) => (
              <li
                key={type}
                className={`flex justify-between items-center mb-1 cursor-pointer ${selectedFacets.type.includes(type) ? 'bg-blue-100' : ''}`}
                onClick={() => handleFacetClick('type', type)}
              >
                <span className="flex items-center">
                  {getIconForType(type)}
                  <span className="ml-2">{type}</span>
                </span>
                <span className="bg-gray-200 rounded-full px-2 py-1 text-xs">{count}</span>
              </li>
            ))}
          </ul>

          {/* Tags */}
          <span
            className="cursor-pointer text-sm mt-4 flex text-left justify-between mb-2 font-semibold text-gray-600 shadow-sm ring-inset ring-gray-300"
            onClick={() => setIsLabelsExpanded(!isLabelsExpanded)}
          >
            Labels
            {isLabelsExpanded ? (
              <ChevronDownIcon className="h-5 w-5 flex-none font-semibold text-indigo-400 cursor-pointer" aria-hidden="true" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 flex-none font-semibold text-indigo-400 cursor-pointer" aria-hidden="true" />
            )}
          </span>
          <div className="justify-between">
            {isLabelsExpanded && (
              <div className="mt-1">
                {tags.map((tag) => (
                  <div key={tag.name} className="mb-2">
                    <TagBadge tag={tag} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Results */}
        <div className="w-3/4">
          {filteredResults.length > 0 ? (
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              {filteredResults.map((result) => (
                <div key={result.id} className="p-4 border-b last:border-b-0 hover:bg-gray-50 transition duration-150 ease-in-out flex items-center">
                  {getIconForType(result.type)}
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold text-gray-800">{highlightText(result.title, searchTerm)}</h3>
                    <p className="text-sm text-gray-500">
                      Type: {highlightText(result.type, searchTerm)} | Domain: {highlightText(result.domain, searchTerm)} | Author: {highlightText(result.author, searchTerm)}
                    </p>
                    <p className="mt-2 text-sm text-gray-600">{highlightText(result.content, searchTerm)}</p>
                  </div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400 flex-shrink-0 ml-4" />
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500">No results found</div>
          )}
        </div>
      </div>

      {/* Domain Modal */}
      {showSetDomainModal && (
        <SetDomainModal resource={selectedResource} isOpen={showSetDomainModal} onClose={() => setShowSetDomainModal(false)} />
      )}
    </div>
  );
};

export default EnterpriseSearch;
