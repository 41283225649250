import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { Card } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import {
    MessageCircle,
    Edit,
    MoreHorizontal,
    ChevronUp,
    Plus,
    Tags,
    Share2,
    Trash,
    UserPlus
} from "lucide-react";
import TextEditorModal from '../modals/TextEditorModal';
import TagModal from '../common/Tags';
import Share from '../modals/Share';
import { addResourceTags, getTags, getResourceTags } from '../../utils/tags';
import { addRole, updateDescription } from '../../utils/resources';
import { fetchData } from '@/utils/baseRequest';

const ChatButton = ({ onClick }) => (
    <Button
        onClick={onClick}
        variant="outline"
        className="group relative inline-flex items-center gap-2.5 py-2 font-semibold transition-all duration-200 hover:shadow-md"
    >
        <span className="relative flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-gradient-to-r from-indigo-500 to-purple-500 group-hover:from-indigo-600 group-hover:to-purple-600 transition-all duration-200">
            <MessageCircle
                className="h-3 w-3 text-white"
                aria-hidden="true"
            />
            <span className="absolute -right-1 -top-1 flex h-2.5 w-2.5">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-indigo-200 opacity-75"></span>
                <span className="relative inline-flex h-2.5 w-2.5 rounded-full bg-indigo-500"></span>
            </span>
        </span>
        <span className="relative">AI Chat</span>
    </Button>
);


export default function AssetInfo({ resourceType, projectId, datasetId, tableId, data }) {
    const navigate = useNavigate();
    const [tags, setTags] = useState([]);
    const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
    const [description, setDescription] = useState(data?.description || '');
    const [isTagModalOpen, setIsTagModalOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [resourceRoles, setResourceRoles] = useState(null);

    const getResourceId = () => {
        const typeToIdMap = {
            tables: data.id_table,
            datasets: data.id_dataset,
            projects: data.id_project,
            docs: data.id_doc,
            lookers: data.id_looker_dashboard,
            notebooks: data.id_notebook,
            datastudios: data.id_datastudio
        };
        return typeToIdMap[resourceType];
    };

    const resourceId = getResourceId();

    const fetchResourceTags = async () => {
        try {
            const resourceTags = await getResourceTags(resourceId);
            setSelectedTags(resourceTags);
        } catch (error) {
            console.error('Error fetching resource tags:', error);
        }
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            const [tagsData, rolesData] = await Promise.all([
                getResourceTags(resourceId),
                fetchResourceRoles()
            ]);
            setSelectedTags(tagsData);
            setResourceRoles(rolesData);
        };

        if (resourceId) {
            fetchInitialData();
        }
    }, [resourceId]);

    const fetchResourceRoles = async () => {
        try {
            const response = await fetchData(`/resource_role/${data.resource_id}`);
            return response?.bindings || [];
        } catch (error) {
            console.error('Error fetching resource roles:', error);
            return [];
        }
    };

    const handleSaveDescription = async (newDescription) => {
        try {
            const body = {
                description: newDescription,
                projectId,
                datasetId,
                tableId,
            };
            const res = await updateDescription(resourceId, JSON.stringify(body));
            console.log(res)
            setDescription(newDescription);
        } catch (error) {
            console.error("Error updating description:", error);
        }
        setIsDescriptionOpen(false);
    };

    const handleShareSave = async (shareDetails) => {
        try {
            await addRole({
                user_email: shareDetails.user_email,
                resource_type: resourceType,
                resource_id: data.resource_id,
                permission_type: shareDetails.role
            });
            await fetchResourceRoles();
        } catch (error) {
            console.error('Error saving share details:', error);
        }
    };

    return (
        <Card className="p-4 space-y-6">
            {/* Chat Button */}
            <ChatButton
                onClick={() => navigate(`/chat?resourceId=${resourceId}`)}
            />

            <Separator />

            {/* Description Section */}
            <div className="space-y-2">
                <div className="flex items-center justify-between">
                    <h3 className="text-sm font-medium">Description</h3>
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setIsDescriptionOpen(true)}
                    >
                        <Edit className="h-4 w-4" />
                    </Button>
                </div>
                {description ? (
                    <p className="text-sm text-muted-foreground">
                        {description.length > 100
                            ? `${description.slice(0, 100)}...`
                            : description}
                    </p>
                ) : (
                    <div className="text-sm text-muted-foreground">
                        No description added yet.
                    </div>
                )}
            </div>

            <Separator />

            {/* Tags Section */}
            <div className="space-y-2">
                <div className="flex items-center justify-between">
                    <h3 className="text-sm font-medium">Tags</h3>
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setIsTagModalOpen(true)}
                    >
                        <Tags className="h-4 w-4" />
                    </Button>
                </div>
                <div className="flex flex-wrap gap-2">
                    {selectedTags.length > 0 ? (
                        selectedTags.map((tag) => (
                            <Badge
                                key={tag.id}
                                variant="secondary"
                                className="cursor-pointer hover:bg-secondary/80"
                                style={{
                                    backgroundColor: tag.color,
                                    color: tag.text_color
                                }}
                                onClick={() => navigate(`/search?query=labels:${tag.name}`)}
                            >
                                {tag.name}
                            </Badge>
                        ))
                    ) : (
                        <p className="text-sm text-muted-foreground">
                            No tags added yet.
                        </p>
                    )}
                </div>
            </div>

            <Separator />

            {/* Sharing Section */}
            <div className="space-y-2">
                <div className="flex items-center justify-between">
                    <h3 className="text-sm font-medium">Shared with</h3>
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setIsShareModalOpen(true)}
                    >
                        <UserPlus className="h-4 w-4" />
                    </Button>
                </div>

                <ScrollArea className="h-[200px] rounded-md border">
                    {resourceRoles ? (
                        <div className="p-4">
                            {resourceRoles.map((binding) => (
                                <Accordion
                                    key={binding.role}
                                    type="single"
                                    collapsible
                                    className="w-full"
                                >
                                    <AccordionItem value={binding.role}>
                                        <AccordionTrigger className="text-sm">
                                            {binding.role.replace('roles/', '')}
                                        </AccordionTrigger>
                                        <AccordionContent>
                                            <div className="space-y-2">
                                                {binding.members.map((member, index) => {
                                                    const [userPart, domainPart] = member.split('@');
                                                    return (
                                                        <div
                                                            key={`${binding.role}-${index}`}
                                                            className="flex items-center justify-between rounded-md p-2 hover:bg-muted"
                                                        >
                                                            <div className="flex flex-col">
                                                                <span className="text-sm font-medium">
                                                                    {userPart}
                                                                </span>
                                                                <span className="text-xs text-muted-foreground">
                                                                    @{domainPart}
                                                                </span>
                                                            </div>
                                                            <DropdownMenu>
                                                                <DropdownMenuTrigger asChild>
                                                                    <Button variant="ghost" size="sm">
                                                                        <MoreHorizontal className="h-4 w-4" />
                                                                    </Button>
                                                                </DropdownMenuTrigger>
                                                                <DropdownMenuContent align="end">
                                                                    <DropdownMenuItem>
                                                                        Edit access
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem className="text-destructive">
                                                                        Remove access
                                                                    </DropdownMenuItem>
                                                                </DropdownMenuContent>
                                                            </DropdownMenu>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </AccordionContent>
                                    </AccordionItem>
                                </Accordion>
                            ))}
                        </div>
                    ) : (
                        <div className="flex items-center justify-center h-full p-4">
                            <div className="text-sm text-muted-foreground">
                                Loading sharing details...
                            </div>
                        </div>
                    )}
                </ScrollArea>
            </div>

            {/* Modals */}

            {isDescriptionOpen && (  // Add this condition
                <TextEditorModal
                    content={description}
                    isOpen={isDescriptionOpen}
                    onClose={() => setIsDescriptionOpen(false)}
                    title="Edit Description"
                    onSave={handleSaveDescription}
                />
            )}

            {isTagModalOpen && (
                <TagModal
                    resource_id={resourceId}
                    onClose={() => {
                        setIsTagModalOpen(false);
                        fetchResourceTags();
                    }}
                />
            )}

            {isShareModalOpen && (
                <Share
                    resourceType={resourceType}
                    onClose={() => setIsShareModalOpen(false)}
                    onSave={handleShareSave}
                />
            )}
        </Card>
    );
}