import { useState, useContext } from "react";
import { Outlet, useNavigate, useParams, useLocation, NavLink } from "react-router-dom";
import { SelectedContext } from "../context/SelectedContext";
import { BuildingOfficeIcon, CreditCardIcon, UsersIcon } from "@heroicons/react/24/outline";
import DomainHeader from "@/components/headers/DomainHeader";

const tabs = [
  { name: "History", href: "history", icon: BuildingOfficeIcon },
  { name: "Team Members", href: "members", icon: UsersIcon },
  { name: "Dags", href: "dags", icon: CreditCardIcon },
  { name: "Assets", href: "assets", icon: CreditCardIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Domain = () => {
  const { selectedDomain } = useContext(SelectedContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { domainId } = useParams();
  const [query, setQuery] = useState('');

  const handleSearch = (searchQuery) => {
    setQuery(searchQuery);
    navigate(`/search?query=${searchQuery}`);
  };

  const currentPath = location.pathname.split('/').pop();
  const activeTab = tabs.find(tab => tab.href === currentPath);

  const getAdditionalBreadcrumbs = () => {
    const breadcrumbs = [];

    if (activeTab) {
      breadcrumbs.push({ label: activeTab.name });
    }

    if (location.pathname.includes('/dags/')) {
      const pathParts = location.pathname.split('/');
      const dagId = pathParts[pathParts.indexOf('dags') + 1];
      if (dagId) {
        breadcrumbs.push({
          label: "dags",
          link: `/domains/${domainId}/dags`
        });
        breadcrumbs.push({ label: `dag ${dagId.slice(0, 8)}...` });
      }
    }

    return breadcrumbs;
  };

  return (
    <>
      <div className="bg-gray-100 px-4">
        <DomainHeader
          onSearch={handleSearch}
        />

        {/* Tabs */}
        <div className="">
          <div className="">
            <div className="mb-4">
              <div className="hidden sm:block">
                <div className="border-b border-border">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <NavLink
                        key={tab.name}
                        to={tab.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "border-indigo-500 text-indigo-600"
                              : "border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-700",
                            "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
                          )
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <tab.icon
                              className={classNames(
                                isActive
                                  ? "text-indigo-600"
                                  : "text-muted-foreground group-hover:text-foreground",
                                "-ml-0.5 mr-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                            {tab.name}
                          </>
                        )}
                      </NavLink>
                    ))}
                  </nav>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
      <div className="px-24">
        <Outlet />
      </div>


    </>
  );
};

export default Domain;