// Add this to your _app.jsx or equivalent
import { Toaster } from "@/components/ui/toaster";

// DagMain.jsx
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import cronstrue from "cronstrue";
import {
  Calendar,
  ChevronDown,
  ChevronRight,
  Search,
  PencilIcon,
  Trash2,
  ArrowUpRight,
  Menu,
  Bell,
  X,
  Tags,
  PlayCircle,
  History,
  FileText,
  Power,
  Loader2,
  Settings,
  LogOut,
  User
} from "lucide-react";

import { useToast } from "@/components/hooks/use-toast";

import { AuthContext } from "../context/AuthContext";
import { deleteDag, fetchDag, publishDomainDag, trigger_dag, updateDomainDag } from "../utils/dag";
import { fetchAllTagList, toggleDag } from "../utils/domain";
import { getResourceTags } from "../utils/tags";
import { PERMISSIONS } from "../context/permissions";
import useAuth from "../context/useAuth";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { Switch } from "@/components/ui/switch";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Badge } from "@/components/ui/badge";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import PermissionButton from "@/components/common/PermissonButton";
import DagTasks from "@/components/dag/DagTasks";
import TagModal from "@/components/common/Tags";
import DeleteModal from "@/components/modals/DeleteModal";
import DagHistory from "@/components/dag/DagHistory";
import DagDocumentation from "@/components/dag/DagEditors";
import DagEdit from "@/components/dag/DagEdit";
import DagHeader from "@/components/headers/DagHeader";
import DomainHeader from "@/components/headers/DomainHeader";

const statusStyles = {
  DISABLED: {
    bg: "bg-gray-100 hover:bg-gray-200/80",
    text: "text-gray-800",
    badge: "bg-gray-100 text-gray-800 border-gray-200"
  },
  RUNNING: {
    bg: "bg-green-100 hover:bg-green-200/80",
    text: "text-green-800",
    badge: "bg-green-100 text-green-800 border-green-200 animate-pulse"
  },
  EDITING: {
    bg: "bg-orange-100 hover:bg-orange-200/80",
    text: "text-orange-800",
    badge: "bg-orange-100 text-orange-800 border-orange-200"
  },
  DELETED: {
    bg: "bg-red-100 hover:bg-red-200/80",
    text: "text-red-800",
    badge: "bg-red-100 text-red-800 border-red-200"
  },
  FAILED: {
    bg: "bg-red-100 hover:bg-red-200/80",
    text: "text-red-800",
    badge: "bg-red-100 text-red-800 border-red-200"
  },
  SCHEDULED: {
    bg: "bg-blue-100 hover:bg-blue-200/80",
    text: "text-blue-800",
    badge: "bg-blue-100 text-blue-800 border-blue-200"
  }
};

const DagSkeleton = () => (
  <div className="space-y-6">
    <div className="space-y-2">
      <Skeleton className="h-4 w-[200px]" />
      <div className="flex gap-2">
        <Skeleton className="h-8 w-[150px]" />
        <Skeleton className="h-8 w-[150px]" />
      </div>
    </div>
    <div className="flex items-center justify-between">
      <div className="space-y-2">
        <Skeleton className="h-8 w-[300px]" />
        <div className="flex gap-2">
          <Skeleton className="h-6 w-[100px]" />
          <Skeleton className="h-6 w-[150px]" />
        </div>
      </div>
      <div className="flex gap-2">
        <Skeleton className="h-10 w-[100px]" />
        <Skeleton className="h-10 w-[100px]" />
        <Skeleton className="h-10 w-[100px]" />
      </div>
    </div>
    <Skeleton className="h-[500px] w-full" />
  </div>
);


const TagBadge = ({ tag, onClick }) => (
  <HoverCard>
    <HoverCardTrigger asChild>
      <Badge
        variant="outline"
        className={cn(
          "cursor-pointer transition-colors hover:bg-secondary/80",
          tag.color,
          tag.text_color
        )}
        onClick={onClick}
      >
        {tag.name}
      </Badge>
    </HoverCardTrigger>
    <HoverCardContent className="w-48">
      <div className="space-y-1">
        <p className="text-sm font-medium">{tag.name}</p>
        {tag.description && (
          <p className="text-sm text-muted-foreground">{tag.description}</p>
        )}
      </div>
    </HoverCardContent>
  </HoverCard>
);

const StatusBadge = ({ status }) => {
  const style = statusStyles[status] || statusStyles.DISABLED;

  return (
    <Badge variant="outline" className={cn("capitalize", style.badge)}>
      {status.toLowerCase()}
    </Badge>
  );
};

// Start of main component
const DagMain = () => {
  const { toast } = useToast();
  const [enabled, setEnabled] = useState(false);
  const [dagName, setDagName] = useState("");
  const [activeTab, setActiveTab] = useState('tasks');
  const [currentDag, setCurrentDag] = useState(null);
  const [schedule, setSchedule] = useState("0 * * * *");
  const [isLoading, setIsLoading] = useState(true);
  const [isTriggering, setIsTriggering] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTagModal, setShowTagModal] = useState(false);
  const [query, setQuery] = useState('');
  const [showEnableDisableSwitch, setShowEnableDisableSwitch] = useState(false);

  const { dagId, domainId } = useParams();
  const navigate = useNavigate();
  const { user, hasPermission } = useAuth();

  const breadcrumbs = [
    {
      label: "dags",
      link: `/domains/${domainId}/dags`
    },
    {
      label: dagName
    }
  ];

  useEffect(() => {
    fetchData(dagId);
    fetchAllTags();
    fetchResourceTagList();
  }, [dagId]);

  useEffect(() => {
    if (currentDag) {
      setDagName(currentDag.dag_name);
      setSchedule(currentDag.schedule);
      setEnabled(currentDag.status !== 'DISABLED');
      setShowEnableDisableSwitch(currentDag.status !== 'EDITING');
      setIsLoading(false);
    }
  }, [currentDag]);

  const fetchData = async (id_dag) => {
    try {
      const dag = await fetchDag(id_dag);
      setCurrentDag(dag);
    } catch (error) {
      toast({
        title: "Error fetching DAG",
        description: "Failed to load DAG information. Please try again.",
        variant: "destructive",
      });
    }
  };

  const fetchAllTags = async () => {
    try {
      const tags = await fetchAllTagList();
    } catch (error) {
      toast({
        title: "Error fetching tags",
        description: "Failed to load available tags.",
        variant: "destructive",
      });
    }
  };

  const fetchResourceTagList = async () => {
    try {
      const resourceTags = await getResourceTags(dagId);
      setSelectedTags(resourceTags);
    } catch (error) {
      toast({
        title: "Error fetching tags",
        description: "Failed to load DAG tags.",
        variant: "destructive",
      });
    }
  };

  const toggleDagStatus = async () => {
    const newStatus = currentDag.status === 'DISABLED' ? 'enable' : 'disable';
    try {
      const response = await toggleDag(newStatus, domainId, dagId);
      if (response) {
        setEnabled(!enabled);
        setCurrentDag(prevDag => ({
          ...prevDag,
          status: prevDag.status === 'SCHEDULED' ? 'DISABLED' : 'SCHEDULED'
        }));
        toast({
          title: `DAG ${newStatus}d`,
          description: `The DAG has been ${newStatus}d successfully.`,
        });
      }
    } catch (error) {
      toast({
        title: "Status change failed",
        description: `Failed to ${newStatus} the DAG. Please try again.`,
        variant: "destructive",
      });
    }
  };

  const updateDag = async (dagData) => {
    try {
      const response = await updateDomainDag(dagId, dagData);
      setCurrentDag(response);
      toast({
        title: "DAG Updated",
        description: "The DAG has been updated successfully.",
      });
      setShowEditModal(false);
    } catch (error) {
      toast({
        title: "Update failed",
        description: "Failed to update DAG. Please try again.",
        variant: "destructive",
      });
    }
  };

  const triggerDag = async () => {
    setIsTriggering(true);
    try {
      await trigger_dag(dagId);
      toast({
        title: "DAG Triggered",
        description: "DAG execution has started successfully.",
      });
    } catch (error) {
      toast({
        title: "Trigger failed",
        description: "Failed to trigger DAG execution. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsTriggering(false);
    }
  };

  const publishDag = async () => {
    setIsPublishing(true);
    try {
      await publishDomainDag(dagId);
      setCurrentDag(prevDag => ({ ...prevDag, status: 'SCHEDULED' }));
      setTimeout(() => setShowEnableDisableSwitch(true), 500);
      toast({
        title: "DAG Published",
        description: "The DAG has been published successfully and is now ready for scheduling.",
      });
    } catch (error) {
      toast({
        title: "Publish failed",
        description: "Failed to publish DAG. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsPublishing(false);
    }
  };

  const handleDeleteDag = async () => {
    try {
      await deleteDag(dagId);
      toast({
        title: "DAG Deleted",
        description: "The DAG has been deleted successfully.",
      });
      navigate(`/domains/${domainId}`);
    } catch (error) {
      toast({
        title: "Delete failed",
        description: "Failed to delete DAG. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (isLoading) {
    return (
      <div className="px-24 mx-auto p-6">
        <DagSkeleton />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background">
      <DomainHeader user={user} additionalBreadcrumbs={breadcrumbs} />

      <div className="px-32 py-6">
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <h1 className="text-2xl font-semibold tracking-tight">{dagName}</h1>
            <div className="flex items-center space-x-2">
              <StatusBadge status={currentDag?.status} />
              <div className="text-sm text-muted-foreground">
                <Calendar className="mr-1 h-4 w-4 inline-block" />
                {cronstrue.toString(schedule)}
              </div>
            </div>
            <div className="flex items-center space-x-2 mt-2">
              {selectedTags.map(tag => (
                <TagBadge
                  key={tag.id}
                  tag={tag}
                  onClick={() => navigate(`/search?query=labels:${tag.name}`)}
                />
              ))}
              <Button
                variant="outline"
                size="sm"
                onClick={() => setShowTagModal(true)}
              >
                <Tags className="h-4 w-4 mr-1" />
                {selectedTags.length ? 'Edit Tags' : 'Add Tags'}
              </Button>
            </div>
          </div>

          <div className="flex items-center space-x-3">
            {currentDag?.status !== 'EDITING' && hasPermission(PERMISSIONS.CHANGE_STATUS_DAG, domainId) && (
              <div className="flex items-center space-x-2">
                <Switch
                  checked={enabled}
                  onCheckedChange={toggleDagStatus}
                />
                <span className="text-sm font-medium">
                  {enabled ? 'Enabled' : 'Disabled'}
                </span>
              </div>
            )}

            <PermissionButton
              permission={PERMISSIONS.DELETE_DAG}
              domainId={domainId}
              resourceId={dagId}
              onClick={() => setShowDeleteModal(true)}
              variant="outline"
              className="text-slate-600 hover:text-slate-900 border-slate-200 hover:bg-slate-100"
            >
              <Trash2 className="h-4 w-4 mr-1" />
              Delete
            </PermissionButton>

            <PermissionButton
              permission={PERMISSIONS.EDIT_DAG}
              domainId={domainId}
              resourceId={dagId}
              onClick={() => setShowEditModal(true)}
              variant="outline"
              className="text-slate-600 hover:text-slate-900 border-slate-200 hover:bg-slate-100"
            >
              <PencilIcon className="h-4 w-4 mr-1" />
              Edit
            </PermissionButton>

            {currentDag?.status === 'EDITING' ? (
              <PermissionButton
                permission={PERMISSIONS.PUBLISH_DAG}
                domainId={domainId}
                resourceId={dagId}
                onClick={publishDag}
                disabled={isPublishing}
                className="bg-orange-500 hover:bg-orange-600 text-white"
              >
                {isPublishing ? (
                  <Loader2 className="h-4 w-4 mr-1 animate-spin" />
                ) : (
                  <ArrowUpRight className="h-4 w-4 mr-1" />
                )}
                Publish
              </PermissionButton>
            ) : (
              currentDag?.status !== 'DISABLED' && (
                <Button
                  onClick={triggerDag}
                  disabled={isTriggering}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white"
                >
                  {isTriggering ? (
                    <Loader2 className="h-4 w-4 mr-1 animate-spin" />
                  ) : (
                    <PlayCircle className="h-4 w-4 mr-1" />
                  )}
                  Trigger
                </Button>
              )
            )}
          </div>
        </div>

        <Tabs defaultValue="tasks" className="mt-6">
          <TabsList>
            <TabsTrigger value="tasks">
              <FileText className="h-4 w-4 mr-1" />
              Tasks
            </TabsTrigger>
            <TabsTrigger value="history">
              <History className="h-4 w-4 mr-1" />
              History
            </TabsTrigger>
            <TabsTrigger value="documentation">
              <FileText className="h-4 w-4 mr-1" />
              Documentation
            </TabsTrigger>
          </TabsList>
          <TabsContent value="tasks" className="mt-4">
            <DagTasks
              taskActiveTab={activeTab}
              dagStatus={currentDag?.status}
            />
          </TabsContent>
          <TabsContent value="history" className="mt-4">
            <DagHistory />
          </TabsContent>
          <TabsContent value="documentation" className="mt-4">
            <DagDocumentation documentation={currentDag?.description} />
          </TabsContent>
        </Tabs>
      </div>

      {showTagModal && (
        <TagModal
          resource_id={dagId}
          onClose={() => {
            setShowTagModal(false);
            fetchResourceTagList();
          }}
        />
      )}

      {showEditModal && (
        <DagEdit
          dag={currentDag}
          onDagEdited={updateDag}
          onClose={() => setShowEditModal(false)}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleDeleteDag}
        />
      )}
    </div>
  );
};

export default DagMain;