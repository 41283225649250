import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    getExpandedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Search, Play } from "lucide-react";

export function TaskDataTable({
    columns,
    data,
    onRowSelectionChange,
    selectedRowIds = [],
    expandedState = {},
    onTriggerSelected,
    dagStatus // Add dagStatus prop to handle state transitions correctly
}) {
    const [sorting, setSorting] = React.useState([]);
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [expanded, setExpanded] = React.useState(expandedState);
    const [globalFilter, setGlobalFilter] = React.useState('');

    // Helper function to determine correct task status based on DAG status
    const getTaskStatus = (currentStatus, newStatus) => {
        // If DAG is in EDITING mode, tasks should stay in EDITING unless disabled
        if (dagStatus === 'EDITING') {
            return newStatus === 'DISABLED' ? 'DISABLED' : 'EDITING';
        }
        
        // If DAG is SCHEDULED, tasks can be SCHEDULED or DISABLED
        if (dagStatus === 'SCHEDULED') {
            return newStatus === 'DISABLED' ? 'DISABLED' : 'SCHEDULED';
        }

        // If DAG is DISABLED, all tasks should be DISABLED
        if (dagStatus === 'DISABLED') {
            return 'DISABLED';
        }

        // Default case - maintain current status
        return currentStatus;
    };

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onExpandedChange: setExpanded,
        state: {
            sorting,
            columnFilters,
            expanded,
            rowSelection: selectedRowIds.reduce((acc, id) => {
                acc[id] = true;
                return acc;
            }, {}),
            globalFilter,
        },
        getSubRows: row => {
            const childIds = row.parent_nodes || [];
            return data.filter(item => childIds.includes(item.id_task));
        },
        onRowSelectionChange: (updater) => {
            if (typeof updater === 'function') {
                const newSelection = updater({});
                onRowSelectionChange(
                    Object.keys(newSelection).filter(key => newSelection[key])
                );
            }
        },
        globalFilterFn: (row, columnId, filterValue) => {
            const value = row.getValue(columnId);
            return String(value).toLowerCase().includes(filterValue.toLowerCase());
        },
        // Add meta data to handle status changes
        meta: {
            getTaskStatus
        }
    });

    return (
        <div className="space-y-4">
            <div className="flex items-center gap-2">
                <div className="relative flex-1">
                    <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                    <Input
                        placeholder="Search tasks..."
                        value={globalFilter}
                        onChange={e => setGlobalFilter(e.target.value)}
                        className="pl-8"
                    />
                </div>
                {selectedRowIds.length > 0 && dagStatus !== 'DISABLED' && (
                    <Button
                        onClick={() => {
                            const selectedTasks = data.filter(task => 
                                selectedRowIds.includes(task.id_task)
                            );
                            onTriggerSelected(selectedTasks);
                        }}
                        variant="default"
                    >
                        <Play className="h-4 w-4 mr-2" />
                        Trigger Selected ({selectedRowIds.length})
                    </Button>
                )}
            </div>

            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead key={header.id}>
                                        {header.isPlaceholder ? null : 
                                            flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    No tasks found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}