import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SelectedContext } from "../../context/SelectedContext";
import cronstrue from "cronstrue";
import { createDomainDag } from "../../utils/dag";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Separator } from "@/components/ui/separator";
import {
  Clock,
  ArrowLeft,
  Save,
  Globe2,
  Users,
  Lock,
} from "lucide-react";

const schedules = [
  { label: "Every Hour", value: "0 * * * *" },
  { label: "Every Day at Midnight", value: "0 0 * * *" },
  { label: "Every Half an Hour", value: "30 0 * * *" },
];

export default function DagCreate() {
  const navigate = useNavigate();
  const { domainId } = useParams();
  const { selectedDomain } = useContext(SelectedContext);
  
  // States from your working code
  const [loading, setLoading] = useState(true);
  const [cron, setCron] = useState("0 * * * *");
  const [dagName, setDagName] = useState("");
  const [description, setDescription] = useState("");
  const [cronReadable, setCronReadable] = useState("");
  const [selectedCron, setSelectedCron] = useState(schedules[0]);

  // Your working handlers
  const handleDagNameChange = (e) => {
    setDagName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCronChange = (value) => {
    setSelectedCron(schedules.find(schedule => schedule.value === value) || schedules[0]);
    setCron(value);
    try {
      setCronReadable(cronstrue.toString(value));
    } catch (error) {
      console.log("error");
      setCronReadable("not a valid cron");
    }
  };

  const handleCustomCronChange = (e) => {
    try {
      console.log(e.target.value);
      setCron(e.target.value);
      setSelectedCron(schedules[0]);
      setCronReadable(cronstrue.toString(e.target.value));
    } catch (error) {
      console.log("error");
      setCronReadable("not a valid cron");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = JSON.stringify({
        id_domain: domainId,
        dag_name: dagName,
        schedule: cron,
        description: description,
      });

      const response = await createDomainDag(data);
      navigate(`/domains/${domainId}/dags/${response.id_dag}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="container max-w-2xl mx-auto py-6">
      <Card>
        <CardHeader className="py-4">
          <div className="flex items-center justify-between">
            <div>
              <CardTitle className="text-lg font-medium">Create New DAG</CardTitle>
              <CardDescription className="text-sm">
                Set up a new DAG with schedule and privacy settings
              </CardDescription>
            </div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button 
                    variant="outline" 
                    size="icon"
                    onClick={() => navigate(`/domains/${domainId}/dags`)}
                  >
                    <ArrowLeft className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  Back to DAGs
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label>DAG Name</Label>
                <Input
                  value={dagName}
                  onChange={handleDagNameChange}
                  placeholder="Enter DAG name"
                />
              </div>

              <div className="space-y-2">
                <Label>Schedule</Label>
                <Select 
                  value={selectedCron.value}
                  onValueChange={handleCronChange}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select schedule" />
                  </SelectTrigger>
                  <SelectContent>
                    {schedules.map((schedule) => (
                      <SelectItem 
                        key={schedule.value} 
                        value={schedule.value}
                      >
                        {schedule.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Input
                  placeholder="Custom cron expression"
                  value={cron}
                  onChange={handleCustomCronChange}
                />
                
                <div className="flex items-center gap-2">
                  <Clock className="h-4 w-4 text-muted-foreground" />
                  <span className="text-sm text-muted-foreground">
                    {cronReadable}
                  </span>
                </div>
              </div>

              <div className="space-y-2">
                <Label>Description</Label>
                <Textarea
                  value={description}
                  onChange={handleDescriptionChange}
                  placeholder="Describe your DAG..."
                  rows={4}
                />
              </div>

              <Separator />

              <div className="space-y-2">
                <Label>Privacy Settings</Label>
                <RadioGroup defaultValue="public" className="space-y-2">
                  <div className="flex items-start space-x-2 p-3 rounded-lg border">
                    <RadioGroupItem value="public" id="public" />
                    <div>
                      <Label htmlFor="public" className="flex items-center gap-2">
                        <Globe2 className="h-4 w-4" />
                        Public access
                      </Label>
                      <p className="text-sm text-muted-foreground">
                        Everyone with the link will see this project
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-2 p-3 rounded-lg border">
                    <RadioGroupItem value="domain" id="domain" />
                    <div>
                      <Label htmlFor="domain" className="flex items-center gap-2">
                        <Users className="h-4 w-4" />
                        Private to domain members
                      </Label>
                      <p className="text-sm text-muted-foreground">
                        Only members of this domain would be able to access
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-2 p-3 rounded-lg border">
                    <RadioGroupItem value="private" id="private" />
                    <div>
                      <Label htmlFor="private" className="flex items-center gap-2">
                        <Lock className="h-4 w-4" />
                        Private to you
                      </Label>
                      <p className="text-sm text-muted-foreground">
                        You are the only one able to access this project
                      </p>
                    </div>
                  </div>
                </RadioGroup>
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className="flex justify-end gap-4">
          <Button
            variant="outline"
            onClick={() => navigate(`/domains/${domainId}/dags`)}
          >
            Cancel
          </Button>
          <Button 
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            <Save className="h-4 w-4 mr-2" />
            Save DAG
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}