import { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";
import { SelectedContext } from "../context/SelectedContext";
import { Switch } from "@headlessui/react";
import { PlayIcon, PauseIcon } from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";

import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import {
  BuildingOfficeIcon,
  CreditCardIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
const parser = require("cron-parser");

// const navigation = [
//   { name: "Home", href: "#", icon: HomeIcon, current: true },
//   { name: "Teams", href: "#", icon: UsersIcon, current: false },
//   { name: "Documents", href: "#", icon: DocumentDuplicateIcon, current: false },
//   { name: "Reports", href: "#", icon: ChartPieIcon, current: false },
//   { name: "Settings", href: "#", icon: Cog6ToothIcon, current: false },
// ];

const navigation = [
  { name: "Home", path: "/", icon: HomeIcon },
  { name: "Teams", path: "/teams", icon: UsersIcon },
  { name: "Documents", path: "/documents", icon: DocumentDuplicateIcon },
  { name: "Reports", path: "/reports", icon: ChartPieIcon },
  { name: "Settings", path: "/settings", icon: Cog6ToothIcon },
];

const tabs = [
  { name: "Details", href: "#", icon: BuildingOfficeIcon, current: false },
  { name: "Team Members", href: "#", icon: UsersIcon, current: true },
  { name: "Dags", href: "#", icon: CreditCardIcon, current: false },
  { name: "Assets", href: "#", icon: CreditCardIcon, current: false },
];

const teams = [
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
  { id: 1, name: "cedata", href: "#", initial: "ce", current: false },
  { id: 2, name: "gmktg", href: "#", initial: "gm", current: false },
  { id: 3, name: "cfcxlb", href: "#", initial: "cf", current: false },
  { id: 1, name: "cfcxla", href: "#", initial: "cf", current: false },
];


const userNavigation = [
  { name: "Profile", href: "#" },
  { name: "Sign out", href: "#" },
];

const people = [
  {
    name: "Lindsay Walton",
    role: "Front-end Developer",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Courtney Henry",
    role: "Designer",
    imageUrl:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Salih Atilay Oto",
    role: "Director of Product",
    imageUrl:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Whitney Francis",
    role: "Copywriter",
    imageUrl:
      "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Leonard Krasner",
    role: "Senior Designer",
    imageUrl:
      "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Floyd Miles",
    role: "Principal Designer",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Documents = () => {
  const [loading, setLoading] = useState(true);
  const { setSelectedDomain } = useContext(SelectedContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dunits, setDunits] = useState([]);
  const [domain, setDomain] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [currentDunitDags, setCurrentDunitDags] = useState([]);

  const navigate = useNavigate();

  const handleDomainClick = (e, dunit) => {
    e.preventDefault();
    setDomain(dunit);
    setSelectedDomain(dunit);
    getDunitDags(dunit.id_dunit);
  };

  const handleDunitDagClick = (e, dag) => {
    console.log(dag);
    e.preventDefault();
    navigate(`/domains/${domain.name}/dags/${dag}`);
  };

  const getNextRun = (cronExpression) => {
    const interval = parser.parseExpression(cronExpression);
    return interval.next().toISOString();
  };

  const createNewDag = (domainId) => {
    createDag();
  };

  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const getDunitDags = async (id_dunit) => {
    const dags = await fetch(
      "http://localhost:8080/dunit/" + id_dunit + "/dags",
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    ).then((response) => response.json());

    console.log(dags);
    setCurrentDunitDags(dags);
  };

  const createDag = async () => {
    // const response = await fetch('http://localhost:8080/dags', {
    //     method: 'POST',
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //         "id_dunit": domain.id_dunit,
    //         "dag_name": makeid(5),
    //         "schedule": "0 0 * * *",
    //         "view": JSON.stringify({
    //             drawflow: {
    //                 Home: {
    //                     data: {
    //                     }
    //                 },
    //             },
    //         }),
    //     })
    // }).then(response => response.json())
    // console.log(response)
    navigate(`/domains/${domain.name}/createDag`);
  };

  useEffect(() => {
    const getDunits = async () => {
      const dunitList = await fetch("http://localhost:8080/dunits/", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }).then((response) => response.json());
      console.log(dunitList);
      setDunits(dunitList);
    };
    getDunits();
  }, []);

  const people = [
    {
      name: "Hisham Zarka",
      imageUrl:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
    },
    {
      name: "Lindsay Walton",
      imageUrl:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
    },
    {
      name: "Lindsay Walton",
      imageUrl:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
    },
    {
      name: "Lindsay Walton",
      imageUrl:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
    },
    // More people...
  ];
  const activityItems = [
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    {
      id: 1,
      person: people[0],
      project: "Workcation",
      commit: "2d89f0c8",
      environment: "production",
      time: "1h",
    },
    // More items...
  ];

  const Loader = () => (
    <div className="w-full h-4 bg-gray-300 rounded-sm overflow-hidden">
      <div className="h-full bg-blue-600 animate-loader"></div>
    </div>
  );

  useEffect(() => {
    // Simulate a delay for fetching data
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return <>Settings</>;
};

export default Documents;
