// components/tasks/columns.jsx
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  ChevronDown,
  ChevronRight,
  MoreHorizontal,
  Play,
  Power,
  Trash,
} from "lucide-react";
import { cn } from "@/lib/utils";
import StatusDot from "./StatusDot";

const statusColorMap = {
  EDITING: "bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-300",
  FAILED: "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300",
  RUNNING: "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300",
  SUCCESS: "bg-emerald-100 text-emerald-800 dark:bg-emerald-900 dark:text-emerald-300",
  SCHEDULED: "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300",
  DISABLED: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
};

export const taskColumns = ({
  onDelete,
  onTrigger,
  onTaskUpdate,
  onRowClick,
  onTerminate,
  dagDisabled,
  dagStatus
}) => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "task_name",
    header: "Task Name",
    cell: ({ row }) => {
      const hasChildren = row.original.parent_nodes?.length > 0;
      const indent = row.depth * 20;

      return (
        <div
          className="flex items-center gap-2"
          style={{ paddingLeft: `${indent}px` }}
        >
          <Button
            variant="ghost"
            size="sm"
            className={cn(
              "p-0 h-6 w-6 hover:bg-transparent",
              !hasChildren && "invisible"
            )}
            onClick={(e) => {
              e.stopPropagation();
              row.toggleExpanded();
            }}
          >
            {row.getIsExpanded() ? (
              <ChevronDown className="h-4 w-4" />
            ) : (
              <ChevronRight className="h-4 w-4" />
            )}
          </Button>
          <span
            className="cursor-pointer hover:text-primary font-medium"
            onClick={() => onRowClick(row.original)}
          >
            {row.getValue("task_name")}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status");
      return (
        <Badge 
          variant="secondary" 
          className={cn("capitalize", statusColorMap[status])}
        >
          {status.toLowerCase()}
        </Badge>
      );
    },
  },
  {
    accessorKey: "task_type",
    header: "Type",
  },
  {
    accessorKey: "statuses",
    header: "Recent Runs",
    cell: ({ row }) => {
      const statuses = [...(row.getValue("statuses") || [])]
        .sort((a, b) => new Date(b.start_ts) - new Date(a.start_ts))
        .slice(0, 8);

      const displayStatuses = new Array(8).fill({ status: 'DISABLED' });
      statuses.forEach((status, index) => {
        displayStatuses[displayStatuses.length - 1 - index] = status;
      });

      return (
        <div className="flex items-center space-x-1">
          {displayStatuses.map((status, index) => (
            <StatusDot
              key={index}
              status={status}
              onTerminate={onTerminate}
            />
          ))}
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const task = row.original;
      
      const getNewStatus = (currentStatus) => {
        if (currentStatus !== 'DISABLED') {
          return 'DISABLED';
        }
        // When enabling, respect the DAG's state
        return dagStatus === 'EDITING' ? 'EDITING' : 
               dagStatus === 'SCHEDULED' ? 'SCHEDULED' : 
               dagStatus;
      };
      
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="sm">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-40">
            <DropdownMenuItem
              onClick={() => onTaskUpdate({
                ...task,
                status: getNewStatus(task.status)
              })}
              disabled={dagDisabled}
              className="gap-2"
            >
              <Power className="h-4 w-4" />
              {task.status === 'DISABLED' ? 'Enable' : 'Disable'}
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => onTrigger(task)}
              disabled={task.status === 'DISABLED' || dagDisabled}
              className="gap-2"
            >
              <Play className="h-4 w-4" />
              Trigger
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => onDelete(task)}
              className="text-red-600 dark:text-red-400 gap-2"
            >
              <Trash className="h-4 w-4" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
