import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FaFile, FaFolder, FaTable } from 'react-icons/fa'; // Example icons
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { SiGoogledocs } from "react-icons/si";
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { VscFileSubmodule } from "react-icons/vsc";
import ReactQuill from 'react-quill';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid';
import { SiJupyter } from "react-icons/si";




export default function NotebookAsset() {

  const [notebook, setNotebook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { domainId, notebookId } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    const fetchNotebook = async () => {
      try {
        const response = await fetch(`http://localhost:8080/resources/notebook/${notebookId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setNotebook(data);
      } catch (error) {
        console.error("A problem occurred with fetching the docs:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchNotebook();
  }, [domainId, notebookId]);

  if (loading) return <div className="py-4">Loading...</div>;
  if (error) return <div className="py-4 text-red-500">Error fetching projects: {error}</div>;

  return (
    <div className="">
      <Breadcrumb notebook={notebook}></Breadcrumb>
      <div className='flex justify-between mt-6 mb-2'>
        <div className='flex '>
          <SiJupyter className="mr-2 text-3xl text-orange-400" />
          <div className="text-lg text-black font-medium  ">{notebook?.name}</div>
        </div>
        <div>

        <a
            type="button"
            className="cursor-pointer mt-1 rounded-md bg-white px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            href={`${notebook.url}`}
            target="_blank"
          >
            Go To Notebook
          </a>
        </div>
      </div>


      {notebook &&
        <ReactQuill
          value={notebook.description || "No documentation yet."}
          readOnly={true}
          theme={"snow"}
          modules={{ toolbar: false }}
        />
      }

    </div>
  );
}

function Breadcrumb() {
  const { notebook, domainId } = useParams();

  const items = [
    {
      name: <VscFileSubmodule className="mr-2 text-3xl text-gray-400" />,
      href: `/domains/${domainId}/assets/notebooks/`
    },
    { name: 'test', href: `/domains/${domainId}/assets/notebooks/${domainId}` },
  ].filter(item => item.name || item.icon);

  return (
    <nav className="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {items.map((item, index) => (
          <li key={index}>
            <div className="flex items-center cursor-pointer">
              {index !== 0 && <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />}
              <Link to={item.href} className="text-sm  font-medium text-gray-600 hover:text-gray-700 cursor-pointer">
                {item.icon ? item.icon : item.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}



