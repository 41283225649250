import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getUserInfo, getUserDomains } from "../utils/user";
import History from "../components/History";
import {
  User2,
  Mail,
  Building2,
  MessageSquare,
  Briefcase,
  CalendarDays,
  Activity,
  MapPin
} from "lucide-react";
import UserIcon from "../components/user/UserIcon";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "@/components/ui/skeleton";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Alert, AlertDescription } from "@/components/ui/alert";
import Timeline from "@/components/user/Timeline";

const User = () => {
  const { userId } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const [userDomains, setUserDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        // First API call for user info
        const info = await getUserInfo(userId);
        setUserInfo(info);
        
        // Second API call for domains in a separate try-catch
        try {
          const domains = await getUserDomains(userId);
          setUserDomains(domains);
        } catch (domainError) {
          console.error('Failed to fetch user domains:', domainError);
          setUserDomains([]); // Set empty domains but don't throw error for whole component
        }
      } catch (err) {
        console.error('Failed to fetch user data:', err);
        setError('Failed to load user information. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const LoadingSkeleton = () => (
    <div className="max-w-6xl mx-auto p-4">
      <div className="space-y-4">
        <Skeleton className="h-8 w-1/3" />
        <Skeleton className="h-4 w-1/4" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
        <div className="md:col-span-2">
          <Skeleton className="h-64 w-full" />
        </div>
        <Skeleton className="h-64 w-full" />
      </div>
    </div>
  );

  if (isLoading) return <LoadingSkeleton />;
  if (error) return <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>;
  if (!userInfo) return <Alert><AlertDescription>User not found</AlertDescription></Alert>;

  return (
    <div className="max-w-6xl mx-auto p-4 space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-xl font-semibold text-foreground/90">Profile</h1>
          <p className="text-sm text-muted-foreground">User information and activity</p>
        </div>
        <Button variant="outline" size="sm" asChild>
          <Link to={`/chat/${userId}`}>
            <MessageSquare className="mr-2 h-4 w-4" />
            Message
          </Link>
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Main Profile Card */}
        <Card className="md:col-span-2">
          <CardContent className="pt-6">
            <div className="flex items-start space-x-4">
              <UserIcon name={userInfo.name} imgUrl={userInfo.img_url} size="lg" />
              <div className="space-y-1 flex-1">
                <h2 className="text-lg font-medium leading-none">{userInfo.name}</h2>
                <p className="text-sm text-muted-foreground">{userInfo.title || 'No title set'}</p>
                <div className="flex items-center space-x-4 mt-3">
                  <div className="flex items-center text-sm text-muted-foreground">
                    <Mail className="h-3.5 w-3.5 mr-1.5" />
                    <span>{userInfo.email}</span>
                  </div>
                  {userInfo.location && (
                    <div className="flex items-center text-sm text-muted-foreground">
                      <MapPin className="h-3.5 w-3.5 mr-1.5" />
                      <span>{userInfo.location}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-6 space-y-4">
              <div className="space-y-2">
                <div className="flex items-center text-sm font-medium">
                  <Briefcase className="h-4 w-4 mr-2 text-muted-foreground" />
                  Role Information
                </div>
                <div className="pl-6 space-y-1">
                  <p className="text-sm">{userInfo.title || 'No position specified'}</p>
                  <p className="text-xs text-muted-foreground">
                    {userInfo.department || 'No department specified'}
                  </p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Domains Card */}
        <Card>
          <CardHeader className="pb-3">
            <CardTitle className="text-sm font-medium flex items-center">
              <Building2 className="h-4 w-4 mr-2" />
              Domains
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[280px] pr-4">
              <div className="space-y-2">
                {userDomains.length > 0 ? (
                  userDomains.map((domain) => (
                    <HoverCard key={domain.id_domain}>
                      <HoverCardTrigger asChild>
                        <div className="flex items-center justify-between p-2 rounded-md border border-border/50 hover:bg-accent/50 transition-colors">
                          <div className="flex items-center space-x-2">
                            <Building2 className="h-3.5 w-3.5 text-muted-foreground" />
                            <span className="text-sm">{domain.name}</span>
                          </div>
                          <Badge variant="secondary" className="text-xs">
                            {domain.role_name}
                          </Badge>
                        </div>
                      </HoverCardTrigger>
                      <HoverCardContent className="w-72">
                        <div className="space-y-2">
                          <h4 className="text-sm font-medium">{domain.name}</h4>
                          <p className="text-xs text-muted-foreground">
                            Role: {domain.role_name}
                          </p>
                          {domain.description && (
                            <p className="text-xs">{domain.description}</p>
                          )}
                        </div>
                      </HoverCardContent>
                    </HoverCard>
                  ))
                ) : (
                  <div className="text-center text-sm text-muted-foreground py-8">
                    No domains associated
                  </div>
                )}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>

      {/* Activity Tabs */}
      <Card>
        <CardHeader className="pb-3">
          <CardTitle className="text-sm font-medium">Activity</CardTitle>
          <CardDescription className="text-xs">Recent history and interactions</CardDescription>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="history">
            <TabsList className="grid w-full grid-cols-2 h-9">
              <TabsTrigger value="history" className="text-xs">
                <Activity className="h-3.5 w-3.5 mr-1.5" />
                History
              </TabsTrigger>
              <TabsTrigger value="timeline" className="text-xs">
                <CalendarDays className="h-3.5 w-3.5 mr-1.5" />
                Timeline
              </TabsTrigger>
            </TabsList>
            <TabsContent value="history" className="mt-4">
              <History hist_type="user" id_user={userId} />
            </TabsContent>
            <TabsContent value="timeline" className="mt-4">
              <Timeline userId={userId} />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
};

export default User;