import { Outlet } from 'react-router-dom';

export default function GCPSettings() {
    return (
        <div className="">
            <div className="">
                GCPSettings
            </div>
        </div>
    );
}